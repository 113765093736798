/*
  Copied/adapted from https://github.com/JustFixNYC/justfix-website/blob/master/src/styles/_design-system.scss
  - wow uses root 10px, org-site uses 16px. All rem units here have been adjusted
  - font files have been relocated to '/public/fonts' and are preloaded/initialized in index.html
 */

$wow-font: "Inconsolata", monospace;
$body-font: "Degular", Arial, Helvetica, sans-serif;
$title-font: "Degular Display", Arial, Helvetica, sans-serif;
$eyebrow-font: "Suisse Int'l Mono", "Courier New", Courier, monospace;

@mixin wow-body-standard {
  font-family: $wow-font;
  font-size: 0.875rem; // 14px
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
  letter-spacing: 0.042rem;
}

@mixin body-standard {
  font-family: $body-font;
  font-size: 0.7rem;
  line-height: 100%;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.01rem;
}

// TYPOGRAPHY-DESKTOP:
@mixin desktop-h1 {
  @include body-standard();
  font-family: $title-font;
  font-size: 3.75rem;
  letter-spacing: 0.03em;
}

@mixin desktop-h2 {
  @include body-standard();
  font-family: $title-font;
  font-size: 2.5rem;
  line-height: 90%;
  letter-spacing: 0.03em;
}

@mixin desktop-h3 {
  @include body-standard();
  font-weight: 600;
  font-size: 1.41rem;
}

@mixin desktop-h4 {
  @include body-standard();
  font-weight: 600;
  font-size: 1.13rem;
  line-height: 120%;
}

@mixin desktop-text-small {
  @include body-standard();
  font-size: 0.875rem;
}

@mixin desktop-text-small-bold {
  @include body-standard();
  font-weight: 600;
  letter-spacing: 0.02em;
}

@mixin desktop-eyebrow {
  @include body-standard();
  font-family: $eyebrow-font;
  line-height: 115%;
  letter-spacing: 0.03em;
  text-transform: uppercase;
}

@mixin desktop-eyebrow-small {
  @include desktop-eyebrow();
  font-size: 0.63rem;
}

// TYPOGRAPHY-MOBILE:
@mixin mobile-body-standard {
  @include body-standard();
  line-height: 125%;
  font-size: 1.25rem;
}

@mixin mobile-h1 {
  @include body-standard();
  font-weight: 600;
  font-size: 2.25rem;
}

@mixin mobile-h2 {
  @include body-standard();
  font-weight: 600;
  font-size: 1.13rem;
  letter-spacing: 0.02em;
  font-variant: all-small-caps;
}

@mixin mobile-h3 {
  @include body-standard();
  font-size: 1.5rem;
  line-height: 110%;
}

@mixin mobile-eyebrow {
  @include body-standard();
  font-family: $eyebrow-font;
  font-size: 0.875rem;
  line-height: 115%;
  letter-spacing: 0.03em;
  text-transform: uppercase;
}

@mixin mobile-text-small {
  @include body-standard();
  font-size: 0.875rem;
}

@mixin mobile-text-small-bold {
  @include mobile-text-small();
  font-weight: 600;
}

@mixin mobile-text-small-link {
  @include mobile-text-small();
  @include link();
  line-height: 115%;
}
