/*
  Copied/adapted from https://github.com/JustFixNYC/justfix-website/blob/master/src/styles/_design-system.scss
  - wow uses root 10px, org-site uses 16px. All rem units here have been adjusted
  - font files have been relocated to '/public/fonts' and are preloaded/initialized in index.html
 */
.btn:not(.btn-link) {
  border: 1px solid #454d5d;
  background-color: #f8f9fa;
  box-shadow: inset -1px -1px 0px 0px #acb3c2, inset 2px 2px 0px 0px #fff;
  transition: none;
  padding: 0.44rem 0.5rem;
  height: auto;
}
.btn:not(.btn-link):hover {
  color: #454d5d;
  background-color: #e9ecef;
  box-shadow: inset -1px -1px 0px 0px #9da6b7, inset 2px 2px 0px 0px #f2f2f2;
}
.btn:not(.btn-link):active {
  color: #fff;
  background-color: #727e96;
  box-shadow: inset 1px 1px 0px 0px #4f596c;
}

.card-body-links .btn:not(.btn-link) {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.btn.btn-primary {
  background-color: #727e96;
  box-shadow: inset -1px -1px 0px 0px #454d5d, inset 2px 2px 0px 0px #8f99ac;
}
.btn.btn-primary:hover {
  color: #fff;
  background-color: #50596b;
  box-shadow: inset -1px -1px 0px 0px #2f3540, inset 2px 2px 0px 0px #727e96;
}
.btn.btn-primary:active {
  color: #fff;
  background-color: #50596b;
  box-shadow: inset 1px 1px 0px 0px #1a1d22;
}

.btn.btn-justfix {
  color: #fff;
  background-color: #5188ff;
  box-shadow: inset -1px -1px 0px 0px #1e65ff, inset 2px 2px 0px 0px #6b99ff;
}
.btn.btn-justfix:hover {
  color: #fff;
  background-color: #3877ff;
  box-shadow: inset -1px -1px 0px 0px #0554ff, inset 2px 2px 0px 0px #5188ff;
}
.btn.btn-justfix:active, .btn.btn-justfix:focus {
  color: #fff;
  background-color: #1e65ff;
  box-shadow: inset 1px 1px 0px 0px #004aea;
}

.btns-social {
  row-gap: 0.5rem;
}
.btns-social .btn {
  margin-bottom: 0;
}
.btns-social .btn:nth-child(1) {
  color: #fff;
  background-color: #3b5998;
  box-shadow: inset -1px -1px 0px 0px #2d4373, inset 2px 2px 0px 0px #4264aa;
}
.btns-social .btn:nth-child(1):hover {
  color: #fff;
  background-color: #344e86;
  box-shadow: inset -1px -1px 0px 0px #263961, inset 2px 2px 0px 0px #3b5998;
}
.btns-social .btn:nth-child(1):active, .btns-social .btn:nth-child(1):focus {
  color: #fff;
  background-color: #2d4373;
  box-shadow: inset 1px 1px 0px 0px #1e2e4f;
}
.btns-social .btn:nth-child(2) {
  color: #fff;
  background-color: #55acee;
  box-shadow: inset -1px -1px 0px 0px #2795e9, inset 2px 2px 0px 0px #6cb7f0;
}
.btns-social .btn:nth-child(2):hover {
  color: #fff;
  background-color: #3ea1ec;
  box-shadow: inset -1px -1px 0px 0px #1689e0, inset 2px 2px 0px 0px #55acee;
}
.btns-social .btn:nth-child(2):active, .btns-social .btn:nth-child(2):focus {
  color: #fff;
  background-color: #2795e9;
  box-shadow: inset 1px 1px 0px 0px #147bc9;
}
.btns-social .btn span {
  display: none;
  margin-left: 5px;
}
@media screen and (min-width: 1260px) {
  .btns-social .btn span {
    display: inline;
  }
}
.btns-social .btn.btn-steps {
  flex: 1 0 0;
}
.btns-social .sms-social {
  text-decoration: none;
}

.btn-group.btn-group-block {
  display: -ms-flexbox;
  display: flex;
}

.btn-group {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.btn-block {
  max-width: none;
}

.icon-mail:before {
  border-radius: 1px;
}

.waou-survey-button {
  flex: 1;
  padding: 0.75rem 1.5rem;
  text-align: center;
  color: #242323;
  background-color: #ffa0c7;
  height: 2.5rem;
}

.waou-survey-button:hover,
.waou-survey-button:active,
.waou-survey-button:focus {
  opacity: 0.7;
}

.button.is-primary,
.button.is-primary:not(.is-outlined) {
  background-color: #242323;
  font-family: "Degular", Arial, Helvetica, sans-serif;
  font-size: 0.7rem;
  line-height: 100%;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.01rem;
  font-family: "Suisse Int'l Mono", "Courier New", Courier, monospace;
  font-size: 0.875rem;
  line-height: 115%;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: #faf8f4;
  border: 1px solid #faf8f4;
  border-radius: 6.25rem;
  padding: 1rem 2rem;
  width: fit-content;
  height: fit-content;
  display: inline-block;
  flex: none;
  box-shadow: 0.25rem 0.25rem 0rem #d4d5d0;
  max-width: 100%;
  white-space: normal;
  word-wrap: break-word;
  text-decoration: none;
  text-transform: unset;
  transition: all 0.1s linear;
  transform: translateX(0rem);
}
.button.is-primary:focus, .button.is-primary:focus-visible, .button.is-primary:hover,
.button.is-primary:not(.is-outlined):focus,
.button.is-primary:not(.is-outlined):focus-visible,
.button.is-primary:not(.is-outlined):hover {
  border-color: initial;
  background-color: #242323;
}
.button.is-primary:hover,
.button.is-primary:not(.is-outlined):hover {
  transition: all 0.1s linear;
  box-shadow: 0rem 0.25rem 0rem 0rem #d4d5d0;
  transform: translateX(0.44rem);
}
.button.is-primary:focus-visible,
.button.is-primary:not(.is-outlined):focus-visible {
  box-shadow: none;
  outline: 2px solid #727e96;
  outline-offset: 2px;
}
.button.is-primary:active, .button.is-primary.active,
.button.is-primary:not(.is-outlined):active,
.button.is-primary:not(.is-outlined).active {
  color: #faf8f4;
  border-style: solid;
  background-color: #242323;
  box-shadow: 0rem -0.25rem 0rem 0rem #d4d5d0;
}
.button.is-primary:disabled,
.button.is-primary:not(.is-outlined):disabled {
  background-color: #676565;
  border: 1px solid #faf8f4;
  border-radius: 6.25rem;
  box-shadow: none;
}

.button.is-secondary,
.button.is-secondary:not(.is-outlined) {
  background-color: #faf8f4;
  font-family: "Degular", Arial, Helvetica, sans-serif;
  font-size: 0.7rem;
  line-height: 100%;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.01rem;
  font-family: "Suisse Int'l Mono", "Courier New", Courier, monospace;
  font-size: 0.875rem;
  line-height: 115%;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: #242323;
  border: 1px solid #242323;
  border-radius: 6.25rem;
  padding: 1rem 2rem;
  width: fit-content;
  height: fit-content;
  display: inline-block;
  flex: none;
  box-shadow: 0.25rem 0.25rem 0rem #d4d5d0;
  max-width: 100%;
  white-space: normal;
  word-wrap: break-word;
  text-decoration: none;
  text-transform: unset;
  transition: all 0.1s linear;
  transform: translateX(0rem);
}
.button.is-secondary:focus, .button.is-secondary:focus-visible, .button.is-secondary:hover,
.button.is-secondary:not(.is-outlined):focus,
.button.is-secondary:not(.is-outlined):focus-visible,
.button.is-secondary:not(.is-outlined):hover {
  border-color: initial;
  background-color: #faf8f4;
}
.button.is-secondary:hover,
.button.is-secondary:not(.is-outlined):hover {
  transition: all 0.1s linear;
  box-shadow: 0rem 0.25rem 0rem 0rem #d4d5d0;
  transform: translateX(0.44rem);
}
.button.is-secondary:focus-visible,
.button.is-secondary:not(.is-outlined):focus-visible {
  box-shadow: none;
  outline: 2px solid #727e96;
  outline-offset: 2px;
}
.button.is-secondary:active, .button.is-secondary.active,
.button.is-secondary:not(.is-outlined):active,
.button.is-secondary:not(.is-outlined).active {
  color: #242323;
  border-style: solid;
  background-color: #faf8f4;
  box-shadow: 0rem -0.25rem 0rem 0rem #d4d5d0;
}
.button.is-secondary:disabled,
.button.is-secondary:not(.is-outlined):disabled {
  background-color: #676565;
  border: 1px solid #faf8f4;
  border-radius: 6.25rem;
  box-shadow: none;
}

.button.is-full-width,
.button.is-full-width:not(.is-outlined) {
  width: 100%;
}

.button.is-text {
  padding: 0;
  color: #242323;
  display: flex;
  text-decoration: underline;
  text-underline-position: under;
}
.button.is-text:focus, .button.is-text:focus-visible {
  outline-style: solid;
  outline-width: 2px;
  outline-color: #5188ff;
}

.buttons.has-addons {
  width: fit-content;
  border-radius: 0.25rem;
  border: 1px solid #faf8f4;
}
.buttons.has-addons .button {
  margin: 0;
  text-decoration: none;
  background-color: #242323;
  color: #faf8f4;
}
.buttons.has-addons .button:focus, .buttons.has-addons .button:focus-visible, .buttons.has-addons .button:hover, .buttons.has-addons .button.is-selected {
  background-color: #faf8f4;
  color: #242323;
  transition: all 0.1s linear;
}
.buttons.has-addons .button.is-selected {
  pointer-events: none;
}

.Indicators .Indicators__content {
  padding: 1rem;
}
@media (min-width: 600px) {
  .Indicators .Indicators__content {
    padding: 1.63rem;
  }
  .Indicators .Indicators__content .title-card,
  .Indicators .Indicators__content .Indicators__links {
    padding-left: 1.63rem;
  }
}
.Indicators .Indicators__content > div > h6 {
  font-size: 0.875rem;
  margin-bottom: 0.37rem;
  text-decoration: underline;
  font-weight: bold;
}
.Indicators .Indicators__content p {
  font-size: 1rem;
  margin-bottom: 1.5rem;
}
@media (min-width: 900px) {
  .Indicators .Indicators__content .columns .column.column-context:last-child {
    padding-left: 1.88rem;
  }
}
.Indicators .Indicators__content .title {
  font-size: 1.25rem;
  text-decoration: none;
  margin-bottom: 0px;
}
.Indicators .Indicators__content .title.viz-title {
  display: block;
  text-align: center;
}
.Indicators .Indicators__content .title-card {
  padding-bottom: 0.875rem;
}
.Indicators .Indicators__content .card {
  margin-bottom: 1.88rem;
}
.Indicators .Indicators__content .card-body-links b {
  display: block;
  border-bottom: 1px solid #727e96;
}
.Indicators .Indicators__content .card-body-links ul {
  margin: 0.63rem 0.31rem;
}
.Indicators .Indicators__content .card-body-links ul li {
  margin-top: 0.31rem;
  line-height: 1.13rem;
}
@media (min-width: 600px) {
  .Indicators .Indicators__content aside {
    width: 32%;
    margin-left: 40px;
    margin-top: 0;
    margin-bottom: 0;
  }
}
.Indicators .Indicators__links {
  padding: 0rem 0.94rem 1.56rem 0rem;
  position: relative;
}
@media (min-width: 600px) {
  .Indicators .Indicators__links {
    display: flex;
  }
  .Indicators .Indicators__links .Indicators__linksContainer:first-child {
    padding-right: 1.88rem;
  }
}
.Indicators .Indicators__links .Indicators__linksContainer {
  max-width: 100%;
}
.Indicators .Indicators__links .Indicators__linksContainer:first-child {
  padding-bottom: 0.63rem;
}
.Indicators .Indicators__links .Indicators__linksContainer .Indicators__linksTitle {
  display: inline-block;
  font-size: 0.94rem;
  margin: 0 1.25rem 0.25rem 0;
}
.Indicators .Indicators__links .Indicators__linksContainer .select__control {
  border-color: #242323;
}
.Indicators .Indicators__links .Indicators__linksContainer .select__control--is-focused,
.Indicators .Indicators__links .Indicators__linksContainer .select__control--menu-is-open {
  box-shadow: 0 0 0 1px #242323;
}
.Indicators .Indicators__links .Indicators__linksContainer .select__menu .select__option--is-focused {
  background-color: #f2f2f2;
}
.Indicators .Indicators__links .Indicators__linksContainer .select__menu .select__option--is-selected {
  background-color: #efe9dc;
  color: #242323;
}
.Indicators .Indicators__links .Indicators__linksContainer .select__control {
  border-color: #242323;
}
.Indicators .Indicators__links .Indicators__linksContainer .select__control--is-focused,
.Indicators .Indicators__links .Indicators__linksContainer .select__control--menu-is-open {
  box-shadow: 0 0 0 1px #242323;
}
.Indicators .Indicators__links .Indicators__linksContainer .select__menu .select__option--is-focused {
  background-color: #f2f2f2;
}
.Indicators .Indicators__links .Indicators__linksContainer .select__menu .select__option--is-selected {
  background-color: #efe9dc;
  color: #242323;
}
.Indicators .Indicators__viz {
  display: flex;
  flex-direction: row;
  margin-top: 0.63rem;
  margin-bottom: 0.31rem;
}
.Indicators .Indicators__viz .Indicators__chart {
  width: 95%;
}
@media (max-width: 599px) {
  .Indicators .Indicators__viz .Indicators__chart {
    width: 85%;
  }
}
.Indicators .Indicators__viz .btn-axis-shift {
  height: 70px;
  border-radius: 30px;
  font-size: 20px;
  align-self: center;
  margin-bottom: 75px;
}
.Indicators .Indicators__viz .btn-off,
.Indicators .Indicators__viz .btn-off:hover,
.Indicators .Indicators__viz .btn-off:active {
  opacity: 0.25;
  cursor: default;
  color: #454d5d;
  border: 1px solid #454d5d;
  background-color: #f8f9fa;
  box-shadow: inset -1px -1px 0px 0px #acb3c2, inset 2px 2px 0px 0px #fff;
}
