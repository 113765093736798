/*
  Copied/adapted from https://github.com/JustFixNYC/justfix-website/blob/master/src/styles/_design-system.scss
  - wow uses root 10px, org-site uses 16px. All rem units here have been adjusted
  - font files have been relocated to '/public/fonts' and are preloaded/initialized in index.html
 */
.BuildingStatsTable table {
  width: 100%;
  border-spacing: 0;
}
.BuildingStatsTable table th {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #727e96;
  background: #e0e5e9;
  padding: 8px 12px;
  text-align: left;
  font-weight: normal;
}
.BuildingStatsTable table th button {
  display: flex;
}
.BuildingStatsTable table td {
  border-top: 1px solid #727e96;
  border-left: 1px solid #727e96;
  padding: 8px 12px;
}
.BuildingStatsTable table tr:first-child th {
  border-top: none;
}
.BuildingStatsTable table tr:first-child td {
  border-top: none;
}
.BuildingStatsTable table .info-icon {
  color: #676565;
  height: 1rem;
  width: 1rem;
}
.BuildingStatsTable .table-row {
  border-top: 1px solid #727e96;
}
.BuildingStatsTable .table-row .timeline-link {
  display: flex;
  font-size: 14px;
  justify-content: center;
  padding: 8px 12px;
}
