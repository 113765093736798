.NotRegisteredPage .HomePage__search {
  background-color: #f8f9fa;
  border: 1px solid #727e96;
  padding: 20px;
  width: 85vw;
}
@media (min-width: 600px) {
  .NotRegisteredPage .HomePage__search {
    max-width: 700px;
    padding: 35px;
    margin: 2.5rem auto;
  }
}
@media (max-width: 599px) {
  .NotRegisteredPage .HomePage__search {
    width: 100%;
    border: none;
  }
}
.NotRegisteredPage .HomePage__search h5 {
  font-size: 1.5em;
}
.NotRegisteredPage .HomePage__search button.is-link {
  text-decoration: underline;
  color: #0072a4;
}
.NotRegisteredPage .HomePage__search button.is-link:hover {
  color: #2f3540;
}
.NotRegisteredPage .HomePage__search .wrapper {
  margin: auto;
}
@media (min-width: 600px) {
  .NotRegisteredPage .HomePage__search .wrapper {
    width: 80%;
  }
}
.NotRegisteredPage .HomePage__search .wrapper .bbl-link {
  text-align: center;
}
.NotRegisteredPage .HomePage__search .wrapper img.streetview {
  margin: 15px auto;
}
.NotRegisteredPage .HomePage__search .wrapper a.btn-block:not(.btn-link):not(.btn-justfix) {
  max-width: 300px;
  margin: 10px auto 0 auto;
}
.NotRegisteredPage .HomePage__search .wrapper p a,
.NotRegisteredPage .HomePage__search .wrapper span a {
  text-decoration: underline;
}
.NotRegisteredPage .HomePage__search .wrapper p a:hover,
.NotRegisteredPage .HomePage__search .wrapper span a:hover {
  text-decoration: none;
}
.NotRegisteredPage .HomePage__search .wrapper a.btn {
  max-width: 100%;
  white-space: normal;
}
@media (max-width: 599px) {
  .NotRegisteredPage .HomePage__search .wrapper a.btn {
    width: 100%;
  }
}
.NotRegisteredPage .HomePage__search .wrapper .card-body {
  margin: 30px auto 0px auto;
}
.NotRegisteredPage .HomePage__search .wrapper .card-body .card-body-table {
  border: 1px solid #727e96;
  border-spacing: 0;
  width: 100%;
}
.NotRegisteredPage .HomePage__search .wrapper .card-body .card-body-table .table-row:not(:last-child) {
  border-bottom: 1px solid #727e96;
}
.NotRegisteredPage .HomePage__search .wrapper .card-body .card-body-table .table-row > div {
  flex: 1;
  padding-bottom: 3px;
  text-align: center;
  font-size: 1.25rem;
}
.NotRegisteredPage .HomePage__search .wrapper .card-body .card-body-table .table-row > div.double {
  flex: 2;
  padding-left: 2px;
}
.NotRegisteredPage .HomePage__search .wrapper .card-body .card-body-table .table-row > div.double label {
  margin-left: -2px;
}
.NotRegisteredPage .HomePage__search .wrapper .card-body .card-body-table .table-row > div:not(:last-child) {
  border-right: 1px solid #727e96;
}
.NotRegisteredPage .HomePage__search .wrapper .card-body .card-body-table .table-row > div:hover,
.NotRegisteredPage .HomePage__search .wrapper .card-body .card-body-table .table-row > div label:hover {
  cursor: help;
}
@media (max-width: 899px) {
  .NotRegisteredPage .HomePage__search .wrapper .card-body .card-body-table .table-row > div {
    font-size: 1rem;
  }
}
.NotRegisteredPage .HomePage__search .wrapper .card-body .card-body-table .table-row > div > label {
  display: block;
  line-height: 1.1;
  font-size: 0.875rem;
  padding: 1px 0;
  border-bottom: 1px solid #727e96;
  margin-bottom: 3px;
  color: #000;
  background-image: linear-gradient(to right top, #f8f9fa 33%, #e0e5e9 33%, #e0e5e9 66%, #f8f9fa 66%);
  background-size: 3px 3px;
}
@media (max-width: 599px) {
  .NotRegisteredPage .HomePage__search .wrapper .card-body .card-body-table .table-row > div > label {
    font-size: 1rem;
  }
}
.NotRegisteredPage .HomePage__search .wrapper .card-body .card-body-table .table-row > div span {
  margin: 0 3px;
}
.NotRegisteredPage .HomePage__search .wrapper .card-body .card-body-table .table-row .table-content {
  margin: 0.63rem 0.47rem;
  line-height: 1rem;
}
.NotRegisteredPage .HomePage__search .wrapper .card-body .card-body-table .table-row .table-small-font {
  font-size: 0.875rem;
}
@media (max-width: 599px) {
  .NotRegisteredPage .HomePage__search .wrapper .card-body .card-body-table .table-row .table-small-font {
    font-size: 1rem;
  }
}
.NotRegisteredPage .HomePage__search .wrapper .card-body .nycha-addresses {
  margin-top: 1.25rem;
}
.NotRegisteredPage .HomePage__search .wrapper .card-body .nycha-addresses b {
  display: block;
  border-bottom: 1px solid #727e96;
}
.NotRegisteredPage .HomePage__search .wrapper .card-body .nycha-addresses b:hover {
  cursor: help;
}
.NotRegisteredPage .HomePage__search .wrapper .card-body .nycha-addresses ul {
  margin-top: 0.63rem;
  margin-left: 0;
  list-style-type: none;
}
.NotRegisteredPage .HomePage__search .wrapper .card-body-links ul,
.NotRegisteredPage .HomePage__search .wrapper .card-body-links ol {
  margin: 0.63rem 0.31rem;
}
.NotRegisteredPage .HomePage__search .wrapper .card-body-links ul li,
.NotRegisteredPage .HomePage__search .wrapper .card-body-links ol li {
  margin-top: 0.31rem;
  line-height: 1.13rem;
}
.NotRegisteredPage .HomePage__search .wrapper .justfix-cta,
.NotRegisteredPage .HomePage__search .wrapper .social-share {
  margin-top: 1.25rem;
}
.NotRegisteredPage .HomePage__search .wrapper .btn-group:first-child .btn {
  border-bottom: none;
}
@media screen and (max-width: 713px) {
  .NotRegisteredPage .HomePage__search .wrapper .btn-group .btn:first-child {
    border-bottom: none;
  }
}

.wowza-styling .NotRegisteredPage,
.wowza-styling .NotRegisteredPage .HomePage__search,
.wowza-styling .NotRegisteredPage .HomePage__content {
  color: #242323;
  background-color: #faf8f4;
  border-color: #242323;
}
