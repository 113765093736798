.LandlordSearch {
  margin-top: 15px;
  margin-bottom: 15px;
}
.LandlordSearch form {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.LandlordSearch .algolia__suggests {
  background-color: #fff;
  border: 1px solid #acb3c2;
  border-top: none;
}
.LandlordSearch .algolia__suggests a.algolia__item {
  display: block;
  text-decoration: none !important;
}
.LandlordSearch .algolia__suggests a.algolia__item .result__snippet {
  display: block;
  padding: 0.47rem 0.63rem;
  color: #454d5d;
}
.LandlordSearch .algolia__suggests a.algolia__item:hover, .LandlordSearch .algolia__suggests a.algolia__item:focus {
  background-color: #454d5d;
  outline: none;
}
.LandlordSearch .algolia__suggests a.algolia__item:hover .result__snippet,
.LandlordSearch .algolia__suggests a.algolia__item:hover .result__snippet b, .LandlordSearch .algolia__suggests a.algolia__item:focus .result__snippet,
.LandlordSearch .algolia__suggests a.algolia__item:focus .result__snippet b {
  color: #fff;
}
.LandlordSearch .search-by {
  margin-top: 0.47rem;
}
