.Loader {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 20;
}
.Loader.Loader-map {
  background-color: #fff;
}
.Loader.Loader--centered {
  margin: 0 auto;
}
.Loader .Loader__content p {
  width: 100%;
  font-size: 18px;
  margin-bottom: 0;
  display: inline-block;
}
.Loader .Loader__content p:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  animation: ellipsis steps(4, end) 1200ms infinite;
  content: "..."; /* ascii code for the ellipsis character */
}
@keyframes ellipsis {
  from {
    width: 0px;
    margin-right: 36px;
  }
  to {
    width: 36px;
    margin-right: 0px;
  }
}
