.AddressPage {
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
}
.AddressPage .AddressPage__info {
  width: 100%;
  padding: 15px 15px 0 15px;
  background-color: #f8f9fa;
  display: flex;
  justify-content: space-between;
}
.AddressPage .AddressPage__info p.small {
  margin-bottom: 0;
}
.AddressPage .AddressPage__info h1 {
  margin-bottom: 0;
  font-size: 1.25rem;
}
.AddressPage .AddressPage__info h1.primary {
  line-height: 20px;
  margin-bottom: 20px;
}
@media (max-width: 599px) {
  .AddressPage .AddressPage__info h1.primary {
    font-size: 1.13rem;
  }
}
.AddressPage .AddressPage__info h1.primary a {
  color: inherit;
  text-decoration: underline;
}
.AddressPage .AddressPage__info h1.primary a:hover,
.AddressPage .AddressPage__info h1.primary a:focus {
  text-decoration: none !important;
}
.AddressPage .AddressPage__info h1 + p {
  line-height: 1;
  color: #727e96;
  margin-bottom: 15px;
}
.AddressPage .AddressPage__info .tab {
  margin-bottom: 0;
  border-bottom: none;
  width: 75%;
}
@media (max-width: 899px) {
  .AddressPage .AddressPage__info .tab {
    width: 100%;
  }
}
.AddressPage .AddressPage__info .tab li.tab-item {
  height: 26px;
  max-width: 105px;
  color: #676565;
  background-color: #faf8f4;
  border: 1px solid #727e96;
  border-bottom: none;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  position: relative;
  margin-right: 15px;
}
@media (max-width: 599px) {
  .AddressPage .AddressPage__info .tab li.tab-item {
    margin-right: 11px;
  }
}
.AddressPage .AddressPage__info .tab li.tab-item:hover {
  cursor: pointer;
}
.AddressPage .AddressPage__info .tab li.tab-item:before, .AddressPage .AddressPage__info .tab li.tab-item:after {
  width: 9px;
  height: 7px;
  position: absolute;
  z-index: 2;
  bottom: 0;
  background: #ffffff url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAHElEQVQYV2NMS0v7P2vWLEYGKIAzMARgKjFUAABWhQgF0A6r6AAAAABJRU5ErkJggg==) repeat;
}
.AddressPage .AddressPage__info .tab li.tab-item:before {
  left: -8px;
}
.AddressPage .AddressPage__info .tab li.tab-item:after {
  right: -8px;
}
.AddressPage .AddressPage__info .tab li.tab-item.active {
  height: 27px;
  top: 1px;
  border-color: #454d5d;
  color: #242323;
  font-weight: 700;
  background-color: #faf8f4;
  z-index: 3;
}
.AddressPage .AddressPage__info .tab li.tab-item.active:before, .AddressPage .AddressPage__info .tab li.tab-item.active:after {
  background: #fff;
  bottom: 0;
}
.AddressPage .AddressPage__info .tab li.tab-item.active a:before, .AddressPage .AddressPage__info .tab li.tab-item.active a:after {
  border-bottom: 1px solid #454d5d;
}
.AddressPage .AddressPage__info .tab li.tab-item.active a:before {
  border-right: 1px solid #454d5d;
}
.AddressPage .AddressPage__info .tab li.tab-item.active a:after {
  border-left: 1px solid #454d5d;
}
.AddressPage .AddressPage__info .tab li.tab-item.active a:focus {
  box-shadow: none;
}
.AddressPage .AddressPage__info .tab li.tab-item a {
  font-size: 0.8125rem;
  line-height: 1.5;
  border-bottom: none;
  padding: 0.19rem 0.13rem;
  position: relative;
}
.AddressPage .AddressPage__info .tab li.tab-item a:focus {
  box-shadow: 0 0 0 2px #454d5d;
}
.AddressPage .AddressPage__info .tab li.tab-item a:before, .AddressPage .AddressPage__info .tab li.tab-item a:after {
  width: 10px;
  height: 9px;
  position: absolute;
  z-index: 3;
  bottom: -1px;
  background: #fff;
  overflow: hidden;
  border-bottom: 1px solid #727e96;
}
.AddressPage .AddressPage__info .tab li.tab-item a:before {
  left: -10px;
  border-bottom-right-radius: 8px;
  border-right: 1px solid #727e96;
}
.AddressPage .AddressPage__info .tab li.tab-item a:after {
  right: -10px;
  border-bottom-left-radius: 8px;
  border-left: 1px solid #727e96;
}
.AddressPage .AddressPage__content {
  flex: 1;
  overflow: hidden;
  border-top: 1px solid #727e96;
}
.AddressPage .AddressPage__content:not(.AddressPage__content-active) {
  display: none;
}
.AddressPage .AddressPage__content .Page__content {
  min-height: calc(100vh - 150px);
}
.AddressPage .AddressPage__viz {
  display: flex;
  align-items: stretch;
  position: relative;
  border-bottom: 1px solid #727e96;
  width: 100%;
}
.AddressPage .AddressPage__summary {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}
.AddressPage .AddressPage__summary::-webkit-scrollbar {
  width: 18px;
  background-color: #fff;
}
.AddressPage .AddressPage__summary::-webkit-scrollbar-track {
  background-color: #fff;
}
.AddressPage .AddressPage__summary::-webkit-scrollbar-track:vertical {
  border-left: 1px solid #727e96;
  border-right: 1px solid #727e96;
}
.AddressPage .AddressPage__summary::-webkit-scrollbar-track:horizontal {
  border-top: 1px solid #727e96;
}
.AddressPage .AddressPage__summary::-webkit-scrollbar-thumb {
  background-color: #cbd3da;
  position: relative;
  margin-top: -1px;
  border: 1px solid #727e96;
  box-shadow: inset -1px -1px 0px 0px #727e96, inset 2px 2px 0px 0px #e9ecef;
}
.AddressPage .AddressPage__summary::-webkit-scrollbar-thumb:vertical {
  border-top: none;
}

.wowza-styling .AddressPage .AddressPage__info,
.wowza-styling .AddressPage .AddressPage__content,
.wowza-styling .AddressPage .DetailView,
.wowza-styling .AddressPage .card,
.wowza-styling .AddressPage .PropertiesSummary__linksTitle,
.wowza-styling .AddressPage .NotRegisteredPage {
  color: #242323;
  background-color: #faf8f4;
  border-color: #242323;
}
.wowza-styling .AddressPage .text-dark {
  color: #242323;
}
