/*
  Copied/adapted from https://github.com/JustFixNYC/justfix-website/blob/master/src/styles/_design-system.scss
  - wow uses root 10px, org-site uses 16px. All rem units here have been adjusted
  - font files have been relocated to '/public/fonts' and are preloaded/initialized in index.html
 */
.password-input-field .password-input-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.password-input-field .password-input-label label {
  font-family: "Inconsolata", monospace;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
  letter-spacing: 0.042rem;
  text-align: left;
}
.password-input-field .password-input-label a {
  color: #242323;
  font-size: 0.8125rem;
}
.password-input-field .password-input {
  display: flex;
}
.password-input-field .password-input input {
  flex: 1;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  font-size: 1rem;
}
.password-input-field .password-input input:focus {
  border: 2px #242323 solid;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}
.password-input-field .password-input input:invalid, .password-input-field .password-input input.invalid {
  border-color: #ff813a;
}
.password-input-field .password-input input:focus:invalid, .password-input-field .password-input input.invalid:focus {
  border-color: #242323;
}
.password-input-field .password-input .show-hide-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #faf8f4;
  background-color: #242323;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  width: 2.5rem;
}
.password-input-field .password-input .show-hide-toggle:focus, .password-input-field .password-input .show-hide-toggle:focus-visible {
  outline-style: solid;
  outline-offset: 2px;
  outline-width: 2px;
  outline-color: #5188ff;
}
.password-input-field .password-input-rules {
  display: flex;
  flex-direction: column;
}
.password-input-field .password-input-rule {
  display: flex;
  font-family: "Degular", Arial, Helvetica, sans-serif;
  font-size: 0.7rem;
  line-height: 100%;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.01rem;
  font-family: "Inconsolata", monospace !important;
  font-size: 0.8125rem !important;
  line-height: 120%;
  text-transform: none !important;
  color: #242323;
  text-align: left;
}
.password-input-field .password-input-rule.invalid {
  color: #ba4300;
}
.password-input-field .password-input-rule.valid {
  color: #1aa551;
}
.password-input-field .password-input-rule svg {
  width: 0.875rem;
  height: 0.875rem;
  vertical-align: text-bottom;
}
.password-input-field .password-input-errors {
  display: flex;
  flex-direction: column;
}
.password-input-field .password-input-errors span {
  display: flex;
  font-size: 0.8125rem;
  color: #ba4300;
}
.password-input-field .password-input-errors span svg {
  height: 0.875rem;
  width: 0.875rem;
}
