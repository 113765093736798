/*
  Copied/adapted from https://github.com/JustFixNYC/justfix-website/blob/master/src/styles/_design-system.scss
  - wow uses root 10px, org-site uses 16px. All rem units here have been adjusted
  - font files have been relocated to '/public/fonts' and are preloaded/initialized in index.html
 */
.btn:not(.btn-link) {
  border: 1px solid #454d5d;
  background-color: #f8f9fa;
  box-shadow: inset -1px -1px 0px 0px #acb3c2, inset 2px 2px 0px 0px #fff;
  transition: none;
  padding: 0.44rem 0.5rem;
  height: auto;
}
.btn:not(.btn-link):hover {
  color: #454d5d;
  background-color: #e9ecef;
  box-shadow: inset -1px -1px 0px 0px #9da6b7, inset 2px 2px 0px 0px #f2f2f2;
}
.btn:not(.btn-link):active {
  color: #fff;
  background-color: #727e96;
  box-shadow: inset 1px 1px 0px 0px #4f596c;
}

.card-body-links .btn:not(.btn-link) {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.btn.btn-primary {
  background-color: #727e96;
  box-shadow: inset -1px -1px 0px 0px #454d5d, inset 2px 2px 0px 0px #8f99ac;
}
.btn.btn-primary:hover {
  color: #fff;
  background-color: #50596b;
  box-shadow: inset -1px -1px 0px 0px #2f3540, inset 2px 2px 0px 0px #727e96;
}
.btn.btn-primary:active {
  color: #fff;
  background-color: #50596b;
  box-shadow: inset 1px 1px 0px 0px #1a1d22;
}

.btn.btn-justfix {
  color: #fff;
  background-color: #5188ff;
  box-shadow: inset -1px -1px 0px 0px #1e65ff, inset 2px 2px 0px 0px #6b99ff;
}
.btn.btn-justfix:hover {
  color: #fff;
  background-color: #3877ff;
  box-shadow: inset -1px -1px 0px 0px #0554ff, inset 2px 2px 0px 0px #5188ff;
}
.btn.btn-justfix:active, .btn.btn-justfix:focus {
  color: #fff;
  background-color: #1e65ff;
  box-shadow: inset 1px 1px 0px 0px #004aea;
}

.btns-social {
  row-gap: 0.5rem;
}
.btns-social .btn {
  margin-bottom: 0;
}
.btns-social .btn:nth-child(1) {
  color: #fff;
  background-color: #3b5998;
  box-shadow: inset -1px -1px 0px 0px #2d4373, inset 2px 2px 0px 0px #4264aa;
}
.btns-social .btn:nth-child(1):hover {
  color: #fff;
  background-color: #344e86;
  box-shadow: inset -1px -1px 0px 0px #263961, inset 2px 2px 0px 0px #3b5998;
}
.btns-social .btn:nth-child(1):active, .btns-social .btn:nth-child(1):focus {
  color: #fff;
  background-color: #2d4373;
  box-shadow: inset 1px 1px 0px 0px #1e2e4f;
}
.btns-social .btn:nth-child(2) {
  color: #fff;
  background-color: #55acee;
  box-shadow: inset -1px -1px 0px 0px #2795e9, inset 2px 2px 0px 0px #6cb7f0;
}
.btns-social .btn:nth-child(2):hover {
  color: #fff;
  background-color: #3ea1ec;
  box-shadow: inset -1px -1px 0px 0px #1689e0, inset 2px 2px 0px 0px #55acee;
}
.btns-social .btn:nth-child(2):active, .btns-social .btn:nth-child(2):focus {
  color: #fff;
  background-color: #2795e9;
  box-shadow: inset 1px 1px 0px 0px #147bc9;
}
.btns-social .btn span {
  display: none;
  margin-left: 5px;
}
@media screen and (min-width: 1260px) {
  .btns-social .btn span {
    display: inline;
  }
}
.btns-social .btn.btn-steps {
  flex: 1 0 0;
}
.btns-social .sms-social {
  text-decoration: none;
}

.btn-group.btn-group-block {
  display: -ms-flexbox;
  display: flex;
}

.btn-group {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.btn-block {
  max-width: none;
}

.icon-mail:before {
  border-radius: 1px;
}

.waou-survey-button {
  flex: 1;
  padding: 0.75rem 1.5rem;
  text-align: center;
  color: #242323;
  background-color: #ffa0c7;
  height: 2.5rem;
}

.waou-survey-button:hover,
.waou-survey-button:active,
.waou-survey-button:focus {
  opacity: 0.7;
}

.button.is-primary,
.button.is-primary:not(.is-outlined) {
  background-color: #242323;
  font-family: "Degular", Arial, Helvetica, sans-serif;
  font-size: 0.7rem;
  line-height: 100%;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.01rem;
  font-family: "Suisse Int'l Mono", "Courier New", Courier, monospace;
  font-size: 0.875rem;
  line-height: 115%;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: #faf8f4;
  border: 1px solid #faf8f4;
  border-radius: 6.25rem;
  padding: 1rem 2rem;
  width: fit-content;
  height: fit-content;
  display: inline-block;
  flex: none;
  box-shadow: 0.25rem 0.25rem 0rem #d4d5d0;
  max-width: 100%;
  white-space: normal;
  word-wrap: break-word;
  text-decoration: none;
  text-transform: unset;
  transition: all 0.1s linear;
  transform: translateX(0rem);
}
.button.is-primary:focus, .button.is-primary:focus-visible, .button.is-primary:hover,
.button.is-primary:not(.is-outlined):focus,
.button.is-primary:not(.is-outlined):focus-visible,
.button.is-primary:not(.is-outlined):hover {
  border-color: initial;
  background-color: #242323;
}
.button.is-primary:hover,
.button.is-primary:not(.is-outlined):hover {
  transition: all 0.1s linear;
  box-shadow: 0rem 0.25rem 0rem 0rem #d4d5d0;
  transform: translateX(0.44rem);
}
.button.is-primary:focus-visible,
.button.is-primary:not(.is-outlined):focus-visible {
  box-shadow: none;
  outline: 2px solid #727e96;
  outline-offset: 2px;
}
.button.is-primary:active, .button.is-primary.active,
.button.is-primary:not(.is-outlined):active,
.button.is-primary:not(.is-outlined).active {
  color: #faf8f4;
  border-style: solid;
  background-color: #242323;
  box-shadow: 0rem -0.25rem 0rem 0rem #d4d5d0;
}
.button.is-primary:disabled,
.button.is-primary:not(.is-outlined):disabled {
  background-color: #676565;
  border: 1px solid #faf8f4;
  border-radius: 6.25rem;
  box-shadow: none;
}

.button.is-secondary,
.button.is-secondary:not(.is-outlined) {
  background-color: #faf8f4;
  font-family: "Degular", Arial, Helvetica, sans-serif;
  font-size: 0.7rem;
  line-height: 100%;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.01rem;
  font-family: "Suisse Int'l Mono", "Courier New", Courier, monospace;
  font-size: 0.875rem;
  line-height: 115%;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: #242323;
  border: 1px solid #242323;
  border-radius: 6.25rem;
  padding: 1rem 2rem;
  width: fit-content;
  height: fit-content;
  display: inline-block;
  flex: none;
  box-shadow: 0.25rem 0.25rem 0rem #d4d5d0;
  max-width: 100%;
  white-space: normal;
  word-wrap: break-word;
  text-decoration: none;
  text-transform: unset;
  transition: all 0.1s linear;
  transform: translateX(0rem);
}
.button.is-secondary:focus, .button.is-secondary:focus-visible, .button.is-secondary:hover,
.button.is-secondary:not(.is-outlined):focus,
.button.is-secondary:not(.is-outlined):focus-visible,
.button.is-secondary:not(.is-outlined):hover {
  border-color: initial;
  background-color: #faf8f4;
}
.button.is-secondary:hover,
.button.is-secondary:not(.is-outlined):hover {
  transition: all 0.1s linear;
  box-shadow: 0rem 0.25rem 0rem 0rem #d4d5d0;
  transform: translateX(0.44rem);
}
.button.is-secondary:focus-visible,
.button.is-secondary:not(.is-outlined):focus-visible {
  box-shadow: none;
  outline: 2px solid #727e96;
  outline-offset: 2px;
}
.button.is-secondary:active, .button.is-secondary.active,
.button.is-secondary:not(.is-outlined):active,
.button.is-secondary:not(.is-outlined).active {
  color: #242323;
  border-style: solid;
  background-color: #faf8f4;
  box-shadow: 0rem -0.25rem 0rem 0rem #d4d5d0;
}
.button.is-secondary:disabled,
.button.is-secondary:not(.is-outlined):disabled {
  background-color: #676565;
  border: 1px solid #faf8f4;
  border-radius: 6.25rem;
  box-shadow: none;
}

.button.is-full-width,
.button.is-full-width:not(.is-outlined) {
  width: 100%;
}

.button.is-text {
  padding: 0;
  color: #242323;
  display: flex;
  text-decoration: underline;
  text-underline-position: under;
}
.button.is-text:focus, .button.is-text:focus-visible {
  outline-style: solid;
  outline-width: 2px;
  outline-color: #5188ff;
}

.buttons.has-addons {
  width: fit-content;
  border-radius: 0.25rem;
  border: 1px solid #faf8f4;
}
.buttons.has-addons .button {
  margin: 0;
  text-decoration: none;
  background-color: #242323;
  color: #faf8f4;
}
.buttons.has-addons .button:focus, .buttons.has-addons .button:focus-visible, .buttons.has-addons .button:hover, .buttons.has-addons .button.is-selected {
  background-color: #faf8f4;
  color: #242323;
  transition: all 0.1s linear;
}
.buttons.has-addons .button.is-selected {
  pointer-events: none;
}

.PropertiesSummary {
  position: relative;
}
.PropertiesSummary .PropertiesSummary__content {
  padding: 30px 45px 104px 30px;
}
@media (min-width: 600px) {
  .PropertiesSummary .PropertiesSummary__content {
    padding: 45px 45px 104px 45px;
  }
}
.PropertiesSummary .PropertiesSummary__content > div > h6 {
  font-size: 0.875rem;
  margin-bottom: 0.37rem;
  text-decoration: underline;
  font-weight: bold;
}
.PropertiesSummary .PropertiesSummary__content > div > h6:first-child {
  width: 100%;
}
.PropertiesSummary .PropertiesSummary__content p {
  font-size: 1rem;
  margin-bottom: 1.5rem;
}
@media (min-width: 900px) {
  .PropertiesSummary .PropertiesSummary__content aside {
    width: 32%;
    margin-left: 40px;
    margin-top: 0;
    margin-bottom: 0;
  }
}
.PropertiesSummary .PropertiesSummary__content .network-legend {
  padding-bottom: 1rem;
  z-index: 10;
  display: grid;
  grid-template-columns: 3rem 1fr;
  gap: 1rem;
  cursor: inherit;
}
.PropertiesSummary .PropertiesSummary__content .network-legend .network-legend-symbol {
  display: flex;
  align-items: center;
  justify-content: center;
}
.PropertiesSummary .PropertiesSummary__content .network-legend .network-legend-symbol:before {
  content: "";
  position: relative;
  display: inline-block;
  flex: none;
}
.PropertiesSummary .PropertiesSummary__content .network-legend .network-legend-symbol.symbol-owner {
  margin: 1rem 0.5rem;
  height: 2rem;
  width: 2rem;
  border-radius: 2rem;
  background-color: #4e4b4b;
}
.PropertiesSummary .PropertiesSummary__content .network-legend .network-legend-symbol.symbol-name {
  height: 0rem;
  padding-top: 0.75rem;
  width: 3rem;
  border-bottom: 3px dashed #1aa551;
}
.PropertiesSummary .PropertiesSummary__content .network-legend .network-legend-symbol.symbol-bizaddr {
  height: 0rem;
  padding-top: 0.75rem;
  width: 3rem;
  border-bottom: 3px solid #5188ff;
}
.PropertiesSummary .PropertiesSummary__content .network-legend .network-legend-label {
  font-weight: bold;
}
.PropertiesSummary .PropertiesSummary__content .network-legend .network-legend-label .network-legend-sublabel {
  font-weight: normal;
}
.PropertiesSummary .PropertiesSummary__content .portfolio-graph-container {
  position: relative;
}
.PropertiesSummary .PropertiesSummary__content .portfolio-graph-container .Loader-map {
  height: calc(55vh + 3.91rem);
}
.PropertiesSummary .PropertiesSummary__content .portfolio-graph-container .portfolio-graph {
  position: relative;
  padding: 0.63rem;
  margin-bottom: 1.25rem;
  border: 1px dashed #242323;
}
.PropertiesSummary .PropertiesSummary__content .portfolio-graph-container .portfolio-graph div:not(.float-left) {
  cursor: pointer;
}
.PropertiesSummary .PropertiesSummary__content .portfolio-graph-container .portfolio-graph .btn-group {
  position: absolute;
  flex-direction: column;
  margin-top: 2.19rem;
  z-index: 10;
}
.PropertiesSummary .PropertiesSummary__content .portfolio-graph-container .portfolio-graph .btn-group .btn {
  flex-grow: 0;
  margin-left: 0;
  font-size: 1.25rem;
}
@media (min-width: 600px) {
  .PropertiesSummary .PropertiesSummary__content .portfolio-graph-container .MapBanner {
    position: absolute;
    z-index: 100;
    bottom: 0.63rem;
    left: 0.63rem;
    margin-bottom: 0;
    width: 23.44rem;
  }
}
.PropertiesSummary .PropertiesSummary__links {
  border: 1px solid #acb3c2;
  padding: 1.25rem 0.94rem;
  position: relative;
}
.PropertiesSummary .PropertiesSummary__links .btn {
  white-space: normal;
}
.PropertiesSummary .PropertiesSummary__links .PropertiesSummary__linksTitle {
  position: absolute;
  top: -19px;
  left: 7px;
  padding: 8px 9px 4px 8px;
  background-color: #fff;
}
.PropertiesSummary .PropertiesSummary__links h6.PropertiesSummary__linksSubtitle {
  font-weight: bold;
  margin-bottom: 0.47rem;
  width: 100%;
}
.PropertiesSummary .PropertiesSummary__links > div:not(:last-child) {
  margin-bottom: 24px;
}
