.HomePage .HomePage__banner {
  padding: 0.63rem 0.94rem;
  background-color: #ffb700;
}
.HomePage .HomePage__banner b {
  color: #454d5d;
}
.HomePage .HomePage__banner .close-button {
  cursor: pointer;
}
.HomePage .HomePage__banner .close-button:hover {
  opacity: 0.7;
}
.HomePage .HomePage__banner .content {
  padding-right: 24px;
  text-align: center;
}
.HomePage .HomePage__banner .content p {
  margin-bottom: 0;
}
.HomePage .HomePage__banner .content a {
  color: #454d5d;
  text-decoration: underline;
  font-weight: 700;
}
.HomePage .HomePage__banner .content a:hover {
  text-decoration: none;
}
.HomePage .HomePage__content {
  width: 85vw;
  margin: 0 auto;
  flex-grow: 1;
}
.HomePage .HomePage__content h1 {
  font-size: 1.25rem;
}
@media (min-width: 600px) {
  .HomePage .HomePage__content {
    width: 65vw;
  }
}
.HomePage .HomePage__search {
  background-color: #f8f9fa;
  border: 1px solid #727e96;
  margin: 75px auto 60px auto;
  padding: 35px;
}
@media (max-width: 599px) {
  .HomePage .HomePage__search {
    margin: 40px auto 15vh auto;
    padding: 20px;
  }
}
.HomePage .HomePage__search.wowza-styling {
  background-color: transparent;
  border: none;
  color: #242323;
}
.HomePage .HomePage__search.wowza-styling h1 {
  text-align: left;
  font-size: 2.25rem;
  font-weight: 600;
}
.HomePage .HomePage__search.wowza-styling h2 {
  font-size: 1rem;
  margin-bottom: 0.75rem;
}
.HomePage .HomePage__search.wowza-styling label {
  margin-left: 0.44rem;
}
.HomePage .HomePage__search.wowza-styling i.form-icon {
  transform: scale(1.5);
  background: transparent;
  border-color: #242323;
  margin-left: -0.13rem;
}
.HomePage .HomePage__search.wowza-styling i.form-icon::before {
  background: #242323;
  border-radius: 0.63rem;
  transform: scale(2);
}
.HomePage .HomePage__search.wowza-styling .chip {
  border-radius: 0.94rem;
  height: 1.13rem;
  padding: 0 0.69rem;
  margin-left: 0.37rem;
  box-shadow: 0 0 0.25rem #242323;
  transform: rotate(-12deg);
  background-color: #242323;
  color: #faf8f4;
  text-transform: uppercase;
  font-size: 0.75rem;
}
.HomePage .HomePage__search.wowza-styling .AddressSearch,
.HomePage .HomePage__search.wowza-styling .LandlordSearch {
  margin-top: 0.94rem;
  margin-bottom: 0.94rem;
}
.HomePage .HomePage__search.wowza-styling .AddressSearch input.form-input,
.HomePage .HomePage__search.wowza-styling .LandlordSearch input.form-input {
  background: inherit;
  color: #242323;
  border-radius: 0.31rem;
  height: 2.81rem;
  border: 1px solid #242323;
}
.HomePage .HomePage__search.wowza-styling .AddressSearch input.form-input:focus,
.HomePage .HomePage__search.wowza-styling .LandlordSearch input.form-input:focus {
  border: 2px #242323 solid;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}
.HomePage .HomePage__search.wowza-styling .AddressSearch input.form-input::placeholder,
.HomePage .HomePage__search.wowza-styling .LandlordSearch input.form-input::placeholder {
  color: transparent;
}
.HomePage .HomePage__samples {
  margin: 0 auto 30px auto;
}
.HomePage .HomePage__samples .container {
  padding-left: 0;
  padding-right: 0;
}
.HomePage .HomePage__samples .HomePage__sample {
  border: 1px dashed #acb3c2;
  padding: 24px;
}
.HomePage .HomePage__samples .HomePage__sample h6 {
  font-weight: bold;
  text-align: center;
}
.HomePage .HomePage__samples .HomePage__sample h6 a {
  color: #454d5d;
  text-decoration: underline;
}
.HomePage .HomePage__samples .HomePage__sample h6 a:hover {
  text-decoration: none;
}
.HomePage .HomePage__samples .portfolio-link {
  margin-top: 1.5rem;
}

.wowza-styling .HomePage {
  background: #faf8f4;
  color: #242323;
  height: fit-content;
}
.wowza-styling .HomePage a:not(.btn-justfix):not(.jfcl-link):not(.algolia__item),
.wowza-styling .HomePage h6 a,
.wowza-styling .HomePage .HomePage__samples .HomePage__sample,
.wowza-styling .HomePage .HomePage__samples .HomePage__sample h6 a,
.wowza-styling .HomePage a .btn,
.wowza-styling .HomePage .EngagementPanel,
.wowza-styling .HomePage .EngagementPanel input {
  color: #242323;
  background-color: #faf8f4;
  border-color: #242323;
}
