.LegalFooter .Links,
.LegalFooter .Disclaimer {
  padding: 16px;
  border-top: 1px solid #e7e9ed;
  background-color: #f8f9fa;
}
.LegalFooter .Links > a,
.LegalFooter .Links a:hover,
.LegalFooter .Links a:focus,
.LegalFooter .Disclaimer > a,
.LegalFooter .Disclaimer a:hover,
.LegalFooter .Disclaimer a:focus {
  text-decoration: none !important;
}
.LegalFooter .Links a,
.LegalFooter .Disclaimer a {
  color: #454d5d;
}
.LegalFooter .Links a.active,
.LegalFooter .Disclaimer a.active {
  text-decoration: underline;
}
.LegalFooter .Links {
  text-align: right;
}
@media screen and (max-width: 840px) {
  .LegalFooter .Links {
    text-align: center;
  }
}
.LegalFooter .Links div.d-flex {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: flex-end;
}
@media screen and (min-width: 841px) {
  .LegalFooter .Links nav {
    margin-bottom: 0.78rem;
  }
}
.LegalFooter .Links nav a {
  display: inline-block;
}
.LegalFooter .Links nav a:not(:first-child) {
  margin-left: 10px;
}
.LegalFooter .Disclaimer p:last-child {
  margin-bottom: 0;
}

.wowza-styling .LegalFooter,
.wowza-styling .LegalFooter .Disclaimer,
.wowza-styling .LegalFooter .Links {
  color: #242323;
  background-color: #faf8f4;
  border-color: #242323;
}
