@import "_vars.scss";

.jf-alert {
  --color-bar-width: 0.94rem;
  display: flex;
  position: relative;
  padding: 0 0 0.63rem calc(var(--color-bar-width) + 0.63rem);
  width: fit-content;
  border-radius: 0.25rem;
  font-family: "Inconsolata", monospace;
  font-size: 0.875rem;
  font-weight: 500;
  text-transform: none;

  &::before {
    content: "";
    width: var(--color-bar-width);
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    border-radius: 0.19rem 0 0 0.19rem;
  }

  .jf-alert__content {
    margin-right: auto;
    padding-top: 0.63rem;
    &:last-child {
      padding-right: 0.63rem;
    }
  }

  .jf-alert__close {
    align-self: flex-start;
    padding: 0.63rem 0.63rem 0.75rem 0.75rem;
    &:focus-visible {
      outline: 2px solid $focus-outline-color;
    }
    .closeIcon {
      height: 0.75rem;
      width: 0.75rem;
      stroke-width: 0.13rem;
    }
  }

  &.is-primary {
    background-color: $justfix-black;
    color: $justfix-white;
    .closeIcon {
      stroke: $justfix-white;
    }
  }

  &.is-secondary {
    background-color: $justfix-white;
    color: $justfix-black;
    .closeIcon {
      stroke: $justfix-black;
    }
  }

  &.is-error {
    padding: 0 0 0.44rem calc(var(--color-bar-width) + 0.5rem);
    &::before {
      background-color: $justfix-orange;
    }
    .jf-alert__content {
      padding-top: 0.44rem;
      &:last-child {
        padding-right: 0.37rem;
      }
    }
  }
  &.is-success::before {
    background-color: $justfix-green;
  }
  &.is-info {
    &.is-secondary {
      border: 1px solid $justfix-grey-400;
      &,
      p,
      a,
      .button.is-text {
        color: $justfix-grey-600;
      }
    }
    &::before {
      background-color: $justfix-blue;
    }
  }
}
