/*
  Copied/adapted from https://github.com/JustFixNYC/justfix-website/blob/master/src/styles/_design-system.scss
  - wow uses root 10px, org-site uses 16px. All rem units here have been adjusted
  - font files have been relocated to '/public/fonts' and are preloaded/initialized in index.html
 */
.user-type-container {
  display: flex;
  flex-direction: column;
}
.user-type-container .jfcl-radio-button__label {
  font-family: "Inconsolata", monospace;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
  letter-spacing: 0.042rem;
}
.user-type-container .jfcl-radio-button__checkmark {
  background-color: white;
}
.user-type-container .jfcl-text-input label {
  display: none;
}
.user-type-container input[type=text] {
  margin: 0 1.75rem 0 1.75rem;
  font-size: 1rem;
  font-family: "Inconsolata", monospace;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
  letter-spacing: 0.042rem;
}
.user-type-container #input-field-error {
  display: flex;
  align-items: center;
  font-size: 0.8125rem;
  color: #ba4300;
}
