.AboutPage hr {
  max-width: 60%;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 0.78rem;
}
@media (max-width: 599px) {
  .AboutPage hr {
    max-width: 100%;
  }
}
.AboutPage li > p {
  margin-bottom: 0;
}

.wowza-styling .AboutPage .contentful-asset {
  border: 1px solid #242323;
}
