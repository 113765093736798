.App .DropdownComponent {
  max-width: 100%;
}
.App .DropdownComponent .dropdown-overlay {
  position: fixed;
  top: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  background-color: #454d5d;
  opacity: 0.6;
  z-index: 100;
}
.App .DropdownComponent .dropdown-overlay.hidden {
  display: none;
}
.App .DropdownComponent .dropdown {
  width: 100%;
}
.App .DropdownComponent .dropdown.is-open {
  z-index: 110;
}
.App .DropdownComponent .dropdown button.dropdown-toggle:focus {
  border: none;
}
.App .DropdownComponent .dropdown button.dropdown-toggle.dropdown-selector-panel {
  font-size: 1.25rem;
  outline: 1px solid #454d5d;
  border: none;
  background-color: #e7e9ed;
  height: 2.75rem;
  padding: 0px;
  display: flex;
  width: 100%;
}
.App .DropdownComponent .dropdown button.dropdown-toggle.dropdown-selector-panel .float-left,
.App .DropdownComponent .dropdown button.dropdown-toggle.dropdown-selector-panel .float-right {
  height: 100%;
  padding: 0.75rem;
}
.App .DropdownComponent .dropdown button.dropdown-toggle.dropdown-selector-panel .float-left {
  flex-grow: 1;
  text-align: start;
  overflow: hidden;
  text-overflow: ellipsis;
}
.App .DropdownComponent .dropdown button.dropdown-toggle.dropdown-selector-panel .float-right {
  border-left: 0.5px solid #454d5d;
}
.App .DropdownComponent .dropdown button.dropdown-toggle.dropdown-selector-panel .icon {
  padding: 1px;
}
.App .DropdownComponent .dropdown button.dropdown-toggle.dropdown-selector-panel.active {
  z-index: 110;
}
.App .DropdownComponent .dropdown button.dropdown-toggle.dropdown-selector-panel.active .icon {
  transition: transform 0.2s linear;
  transform: scaleY(-1) translateY(-3px);
}
.App .DropdownComponent .dropdown .menu {
  z-index: 110;
  padding: 0.16rem 0.31rem;
  font-size: 1rem;
}
.App .DropdownComponent .dropdown .menu .menu-item a:focus {
  outline-offset: 0px;
}
.App .DropdownComponent .dropdown .menu .menu-item a:active, .App .DropdownComponent .dropdown .menu .menu-item a.active {
  background: #e7e9ed;
}
.App .DropdownComponent .dropdown .menu.d-none {
  display: none;
}
.App .DropdownComponent .dropdown .menu.d-block {
  display: block;
}
.App .DropdownComponent .dropdown-selector-panel + .menu {
  padding: 0.78rem 0.31rem;
  font-size: 1.25rem;
  box-shadow: none;
  transform: translateY(1px);
}
.App .DropdownComponent .dropdown-selector-panel + .menu .menu-item {
  display: inline-block;
  margin: 0.63rem 0;
}
