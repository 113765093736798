/*
  Copied/adapted from https://github.com/JustFixNYC/justfix-website/blob/master/src/styles/_design-system.scss
  - wow uses root 10px, org-site uses 16px. All rem units here have been adjusted
  - font files have been relocated to '/public/fonts' and are preloaded/initialized in index.html
 */
.EmailAlertSignup {
  margin-top: 1.25rem;
}
.EmailAlertSignup .login-subscribe-alert-container {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  top: 0;
  left: 0;
  width: 100%;
  pointer-events: none;
}
.EmailAlertSignup .login-subscribe-alert-container .login-subscribe-alert {
  position: relative;
  transform: translateY(-100%);
}
.EmailAlertSignup .login-subscribe-alert-container .login-subscribe-alert-enter {
  transform: translateY(-100%);
}
.EmailAlertSignup .login-subscribe-alert-container .login-subscribe-alert-enter-active {
  transform: translatey(215%);
  transition: transform 500ms ease-in-out;
}
.EmailAlertSignup .login-subscribe-alert-container .login-subscribe-alert-enter-done {
  transform: translateY(215%);
}
.EmailAlertSignup .login-subscribe-alert-container .login-subscribe-alert-exit {
  transform: translateY(215%);
}
.EmailAlertSignup .login-subscribe-alert-container .login-subscribe-alert-exit-active {
  transform: translateY(-100%);
  transition: transform 500ms ease-in-out;
}
.EmailAlertSignup .building-subscribe {
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 1.5rem;
}
.EmailAlertSignup .building-subscribe .jfcl-button {
  align-self: center;
}
.EmailAlertSignup .building-subscribe .jfcl-button .jfcl-button__label {
  align-items: center;
}
.EmailAlertSignup .building-subscribe .status-title {
  display: flex;
  align-items: center;
}
.EmailAlertSignup .building-subscribe .status-title svg {
  margin-right: 0.63rem;
}
