@import "_vars";
@import "_typography";
@import "_button.scss";
@import "_scrollbar.scss";

.multiselect-container {
  display: flex;
  flex-direction: column;
  font-family: $wow-font;
  font-style: normal;
  font-weight: 600;
  @include browser("Firefox") {
    font-weight: 500;
  }
  line-height: 100%;
  letter-spacing: 0.02em;
  padding: 0.75rem;
  gap: 0.75rem;

  .multiselect__selected-value-container {
    display: flex;
    flex-wrap: wrap;
    gap: 0.37rem;
    padding: 0;
    margin: 0;

    .multiselect__multi-value,
    .multiselect__show-more-button {
      border-radius: 0.5rem;
      padding: 0.5rem 0.875rem;
      margin: 0;
      white-space: pre-line;
      gap: 0.31rem;
      width: fit-content;
    }

    .multiselect__multi-value {
      background-color: $justfix-black;
      .multiselect__multi-value__label,
      .multiselect__multi-value__remove {
        color: $justfix-grey-50;
      }
      .multiselect__multi-value__label {
        text-transform: uppercase;
        font-size: 0.875rem;
        padding: 0;
        white-space: normal;
      }

      .multiselect__multi-value__remove {
        padding: 0;
        &:hover {
          background-color: inherit;
        }
        svg {
          width: 0.63rem;
          height: 0.63rem;
          stroke-width: 3px;
        }
      }
    }

    .multiselect__show-more-button {
      border: 1px solid $justfix-black;
      padding: 0.5rem 0.37rem;
    }
  }
  .multiselect__selected-value-control-container {
    display: flex;
    .button.is-text {
      color: $justfix-grey-700;
      padding: 0.5rem 0;
      &.multiselect__clear-value-button {
        margin-left: auto;
      }
    }
  }

  .multiselect__control {
    background: none;
    border: none;
    box-shadow: none;
    min-height: unset;

    .multiselect__value-container,
    .multiselect__input-container {
      flex: 1 1 100%;
    }

    .multiselect__value-container {
      padding: 0;
      margin: 0;

      .multiselect__input-container,
      .multiselect__placeholder {
        padding: 0.31rem;
        margin: 0;
      }
      .multiselect__placeholder {
        background-color: $justfix-white;
        height: 100%;
        display: flex;
        align-items: center;
      }

      .multiselect__input-container {
        border: 1px solid $justfix-black;
        border-radius: 0.13rem 0.13rem 0 0;
        font-size: 1rem;
        color: $justfix-black;

        &:focus-within {
          border: 2px $justfix-black solid;
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
        }

        &::placeholder,
        &::-moz-placeholder,
        &::-webkit-input-placeholder {
          color: $justfix-grey-600;
          text-transform: uppercase;
        }
      }
    }
  }
  .multiselect__menu {
    margin: 0;
    border-radius: 0 0 0.13rem 0.13rem;
    position: relative;
    border: 1px solid $justfix-grey-400;
    border-top: none;
    box-shadow: none;
    color: $justfix-black;
    background-color: $justfix-white;

    .multiselect__menu-list {
      max-height: 15.63rem;
      @include for-tablet-portrait-up() {
        @include scrollbar_wowza(inherit, 0.5rem, $justfix-grey-50);
      }

      .multiselect__option {
        background-color: unset;
        display: flex;
        align-items: center;
        gap: 1rem;
        cursor: pointer;

        &.multiselect__option--is-focused {
          text-decoration: underline;
        }

        &.multiselect__option--is-selected {
          background-color: unset;
          color: inherit;
          .multiselect__option-checkbox {
            background-color: $justfix-black;
            color: $justfix-white;
          }
        }
        .multiselect__option-checkbox {
          --box-size: 1.13rem;
          height: var(--box-size);
          width: var(--box-size);
          min-height: var(--box-size);
          min-width: var(--box-size);
          border-radius: 0.25rem;
          border: 1px solid $justfix-black;
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            height: 0.63rem;
            width: 0.875rem;
          }
        }
      }
    }
  }
  button.button.is-primary {
    align-self: center;
    width: 100%;
    letter-spacing: 0.02em;
  }
}

.multiselect-container.has-error
  .multiselect__control
  .multiselect__value-container
  .multiselect__input-container {
  border: 3px solid $justfix-orange;
}
