.UnsubscribePage div.page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  margin-top: 4rem;
}
@media (max-width: 599px) {
  .UnsubscribePage div.page-container {
    margin: 1.5rem 1rem;
  }
}
.UnsubscribePage h1,
.UnsubscribePage h2 {
  margin: 0;
  align-self: start;
}
.UnsubscribePage h1,
.UnsubscribePage h1 a,
.UnsubscribePage h2,
.UnsubscribePage h2 a {
  font-weight: 500;
}
.UnsubscribePage h1 {
  font-size: 1.5rem;
}
.UnsubscribePage h2,
.UnsubscribePage h2 a {
  font-size: 0.875rem;
}
.UnsubscribePage h1 + h2 {
  margin-top: 1rem;
}
.UnsubscribePage .jfcl-button.jfcl-variant-primary {
  align-self: start;
  margin-top: 1.5rem;
}
.UnsubscribePage .subscriptions-container {
  width: 100%;
  margin-top: 3rem;
}
.UnsubscribePage p.settings-section {
  margin-bottom: 2rem;
}
.UnsubscribePage .wow-footer {
  margin-top: auto;
  text-align: center;
}
.UnsubscribePage .wow-footer,
.UnsubscribePage .wow-footer a {
  font-size: 0.875rem;
}
