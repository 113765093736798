/*
  Copied/adapted from https://github.com/JustFixNYC/justfix-website/blob/master/src/styles/_design-system.scss
  - wow uses root 10px, org-site uses 16px. All rem units here have been adjusted
  - font files have been relocated to '/public/fonts' and are preloaded/initialized in index.html
 */
.Card .card-label-container:hover,
.Card .table-content:hover {
  cursor: default !important;
}
.Card .card-label-container {
  min-height: 2.37rem;
  display: flex !important;
  justify-content: center;
  align-items: center;
  margin-bottom: 0px !important;
}
.Card .card-label-container .pill-new {
  font-family: "Degular", Arial, Helvetica, sans-serif;
  letter-spacing: 0.02em;
  background: #ffba33;
  padding: 0.25rem 0.5rem;
  border-radius: 1rem;
  width: fit-content;
  margin-right: 0.5rem !important;
}
@media (max-width: 599px) {
  .Card .card-label-container {
    width: unset;
    justify-content: center;
  }
  .Card .card-label-container .pill-new {
    margin: 0;
  }
}
.Card .table-small-font {
  padding: 0 !important;
}
.Card .table-content {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 1.5rem;
  margin: 0px !important;
}
.Card .card-description {
  font-size: 0.875rem;
  text-align: left;
  line-height: 117%;
}
@media (max-width: 599px) {
  .Card .card-description {
    font-size: 1rem;
  }
}
.Card .jfcl-button.is-full-width {
  width: 100%;
}
@media (max-width: 599px) {
  .Card .jfcl-button.jfcl-variant-primary {
    width: -webkit-fill-available !important;
  }
}
