@import "styles/spectre/src/_variables.scss";

$primary: #5764c6;
$secondary: #eff1fa;
$dark: #454d5d;
$gray-dark: #727e96;
$gray: #acb3c2;
$gray-light: #e7e9ed;
$border: #f0f1f4;
$background: #f8f9fa;
$light: #fff;
$success: #32b643;
$warning: #ffb700;
$error: #e85600;

$justfix-table-grey: #f2f2f2;
$justfix-table-white: #f7f7f7;
$focus-outline-color: #727e96;

$wowza-background: #faf8f4;
$wowza-dark: #242323;
$wowza-orange: #ff813a;
$wowza-warning: #ffba33;

$justfix-blue: #0096d7;

$facebook: #3b5998;
$twitter: #55acee;

$primary-color: $dark;
$primary-color-dark: darken($primary-color, 3%);
$primary-color-light: lighten($primary-color, 3%);
$secondary-color: lighten($primary-color, 40%);
$secondary-color-dark: darken($secondary-color, 3%);
$secondary-color-light: lighten($secondary-color, 3%);

$link-color: $primary-color;
$link-color-dark: darken($link-color, 10%);

$search-marker: #ff5722;
$assoc-marker: #ff9800;

$border-radius: 0;

// $retro-1: #d5cec4;
// $retro-2: #aca091;
// $retro-3: #8e7c65;
$retro-1: #dbd0bd;
$retro-2: #9b9486;
$retro-3: #5c574f;
$retro-4: #4f4b44;
$retro-5: #35332e;

// responsive sizes:
$size-lg: 899px;

// mixins:
@mixin for-phone-only {
  @media (max-width: 599px) {
    @content;
  }
}
@mixin for-tablet-landscape-down {
  @media (max-width: 899px) {
    @content;
  }
}
@mixin for-desktop-down {
  @media (max-width: 1199px) {
    @content;
  }
}
@mixin for-tablet-portrait-up {
  @media (min-width: 600px) {
    @content;
  }
}
@mixin for-tablet-landscape-up {
  @media (min-width: 900px) {
    @content;
  }
}
@mixin for-desktop-up {
  @media (min-width: 1200px) {
    @content;
  }
}
@mixin for-big-desktop-up {
  @media (min-width: 1800px) {
    @content;
  }
}

// user-agent is added as attribute to html so we can look for browsers from scss files
// https://aslanbakan.com/en/blog/browser-and-device-specific-css-styles-with-sass-and-less-mixins/
@mixin browser($browsers) {
  @each $browser in $browsers {
    html[data-browser*="#{$browser}"] & {
      @content;
    }
  }
}
@mixin browser-not($browsers) {
  @each $browser in $browsers {
    :not(html[data-browser*="#{$browser}"]) & {
      @content;
    }
  }
}

// REBRAND VARIABLES

// COLORS:
$justfix-black: #242323;
$justfix-white: #faf8f4;
$justfix-white-100: #faf8f4;
$justfix-white-200: #efe9dc;
$justfix-grey: #9a9898;
$justfix-grey-50: #f2f2f2;
$justfix-grey-600: #676565;
$justfix-grey-700: #4e4b4b;
$justfix-grey-400: #9a9898;

$justfix-green: #1aa551;
$justfix-pink: #ffa0c7;
$justfix-yellow: #ffba33;
$justfix-orange: #ff813a;
$justfix-blue: #5188ff;

// SPACING:
$spacing-01: 0.125rem; // 2px
$spacing-02: 0.25rem; // 4px
$spacing-03: 0.5rem; // 8px
$spacing-04: 0.75rem; // 12px
$spacing-05: 1rem; // 16px
$spacing-06: 1.5rem; // 24px
$spacing-07: 2rem; // 32px
$spacing-08: 2.5rem; // 40px
$spacing-09: 3rem; // 48px
$spacing-10: 4rem; // 64px
$spacing-11: 5rem; // 80px
$spacing-12: 6rem; // 96px
$spacing-13: 10rem; // 160px
