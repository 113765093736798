.ReactModal__Overlay--after-open {
  z-index: 120;
}
.ReactModal__Overlay--after-open .ReactModal__Close {
  position: absolute;
  top: 1.13rem;
  right: 1.5rem;
  transition: none;
}
.ReactModal__Overlay--after-open .ReactModal__Content {
  transform: translate(-50%, -50%);
  min-width: 300px;
  max-height: 90%;
}
.ReactModal__Overlay--after-open .ReactModal__Content h5 {
  margin-bottom: 0.94rem;
  margin-top: 1.56rem;
}
.ReactModal__Overlay--after-open .ReactModal__Content .first-header {
  margin-top: 0;
}
.ReactModal__Overlay--after-open .ReactModal__Content .btn {
  white-space: normal;
}
.ReactModal__Overlay--after-open .ReactModal__Content .img-responsive {
  border: 1px solid #454d5d;
}
@media (max-width: 599px) {
  .ReactModal__Overlay--after-open .ReactModal__Content {
    padding: 30px 20px !important;
  }
  .ReactModal__Overlay--after-open .ReactModal__Content h5 {
    margin-top: 0;
  }
  .ReactModal__Overlay--after-open .ReactModal__Content ul {
    margin-left: 0;
  }
  .ReactModal__Overlay--after-open .ReactModal__Content ul li {
    margin: 0;
  }
}
