.DetailView {
  position: relative;
  display: inline-block;
  width: 70vw;
  height: 100%;
  overflow: visible;
  z-index: 10;
  background-color: #ffffff;
  border-left: 1px solid #454d5d;
}
@media (min-width: 900px) {
  .DetailView {
    width: calc(((100vw - 336px) / 2) + 336px);
  }
  .DetailView .detail-column-right {
    max-width: 336px;
  }
  .DetailView .detail-column-left {
    flex-grow: 1 !important;
  }
}
@media (max-width: 599px) {
  .DetailView {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100vw;
    transform: translateX(100%);
  }
  .DetailView.DetailView-enter {
    transform: translateX(100%);
    transition: transform 500ms ease-in-out;
  }
  .DetailView.DetailView-enter-active {
    transform: translateX(0);
  }
  .DetailView.DetailView-enter-done {
    transform: translateX(0);
  }
  .DetailView.DetailView-exit {
    transform: translateX(0);
    transition: transform 500ms ease-in-out;
  }
  .DetailView.DetailView-exit-active {
    transform: translateX(100%);
  }
  .DetailView.DetailView-exit-done {
    transform: translateX(100%);
  }
}
.DetailView .DetailView__wrapper {
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: scrollbar;
}
.DetailView .DetailView__wrapper::-webkit-scrollbar {
  width: 18px;
  background-color: #fff;
}
.DetailView .DetailView__wrapper::-webkit-scrollbar-track {
  background-color: #fff;
}
.DetailView .DetailView__wrapper::-webkit-scrollbar-track:vertical {
  border-left: 1px solid #727e96;
  border-right: 1px solid #727e96;
}
.DetailView .DetailView__wrapper::-webkit-scrollbar-track:horizontal {
  border-top: 1px solid #727e96;
}
.DetailView .DetailView__wrapper::-webkit-scrollbar-thumb {
  background-color: #cbd3da;
  position: relative;
  margin-top: -1px;
  border: 1px solid #727e96;
  box-shadow: inset -1px -1px 0px 0px #727e96, inset 2px 2px 0px 0px #e9ecef;
}
.DetailView .DetailView__wrapper::-webkit-scrollbar-thumb:vertical {
  border-top: none;
}
.DetailView .DetailView__close {
  position: absolute;
  top: -1px;
  left: -66px;
  width: 66px;
  height: 42px;
  transform: translateZ(0);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border: 1px solid #727e96;
}
.DetailView .DetailView__close .btn {
  font-size: 18px;
}
.DetailView .DetailView__card {
  border: none;
}
.DetailView .DetailView__card p {
  line-height: 1.13rem;
  margin-bottom: 15px;
}
.DetailView .DetailView__card p.text-danger {
  margin-top: 15px;
}
.DetailView .DetailView__card ul {
  margin: 0.63rem 0.31rem;
}
.DetailView .DetailView__card ul li {
  margin-top: 0.31rem;
}
.DetailView .DetailView__card .columns.main-content-columns {
  margin-left: 0;
  margin-right: 0;
}
.DetailView .DetailView__card .DetailView__mobilePortfolioView {
  padding: 0.8125rem 1.25rem;
  display: none;
}
@media (max-width: 599px) {
  .DetailView .DetailView__card .DetailView__mobilePortfolioView {
    display: block;
  }
}
.DetailView .DetailView__card .DetailView__mobilePortfolioView button {
  font-size: 14px;
}
.DetailView .DetailView__card .DetailView__mobilePortfolioView button span {
  font-weight: bold;
  text-decoration: underline;
}
.DetailView .DetailView__card .card-header {
  padding-bottom: 0.94rem;
}
@media (max-width: 599px) {
  .DetailView .DetailView__card .card-header {
    padding: 0.94rem 0.63rem;
  }
}
.DetailView .DetailView__card .card-header .card-title {
  margin-bottom: 0;
}
.DetailView .DetailView__card .card-header a {
  margin-top: 0.19rem;
  margin-bottom: 0;
  text-decoration: underline;
  cursor: pointer;
  color: #454d5d;
}
@media (max-width: 599px) {
  .DetailView .DetailView__card .card-header a {
    display: none;
  }
}
.DetailView .DetailView__card .card-image {
  padding-top: 0;
}
@media (max-width: 599px) {
  .DetailView .DetailView__card .card-image {
    display: none;
  }
}
.DetailView .DetailView__card .card-image figure {
  display: inline-block;
  width: 100%;
}
.DetailView .DetailView__card .card-image figure img {
  object-fit: cover;
  object-position: bottom;
  width: 100%;
  max-height: 300px;
}
@media (max-width: 899px) {
  .DetailView .DetailView__card .card-image figure img {
    max-height: 180px;
  }
}
.DetailView .DetailView__card .card-image figcaption {
  margin: 0;
  padding-top: 1.5rem;
  padding-bottom: 0.5rem;
}
@media (max-width: 899px) {
  .DetailView .DetailView__card .card-image figcaption {
    padding-left: 1.56rem;
  }
}
@media (max-width: 599px) {
  .DetailView .DetailView__card .card-image figcaption {
    padding-top: 0;
    padding-right: 1.25rem;
    text-align: right;
  }
  .DetailView .DetailView__card .card-image figcaption a {
    font-size: 0.875rem;
  }
}
.DetailView .DetailView__card .hover-info {
  display: flex;
}
.DetailView .DetailView__card .hover-info i {
  margin-left: auto;
}
.DetailView .DetailView__card .card-body {
  padding-top: 0;
}
@media (max-width: 599px) {
  .DetailView .DetailView__card .card-body {
    padding-top: 0 !important;
  }
  .DetailView .DetailView__card .card-body:last-child {
    padding: 0.63rem;
  }
}
@media (min-width: 600px) {
  .DetailView .DetailView__card .card-body {
    padding-right: 1.88rem;
  }
}
@media (min-width: 900px) {
  .DetailView .DetailView__card .card-body.column-right {
    padding-right: 0;
    padding-left: 0;
  }
}
.DetailView .DetailView__card .card-body-table {
  border: 1px solid #727e96;
  border-spacing: 0;
  width: 100%;
}
.DetailView .DetailView__card .card-body-table .table-row:not(:last-child) {
  border-bottom: 1px solid #727e96;
}
.DetailView .DetailView__card .card-body-table .table-row > div {
  flex: 1;
  padding-bottom: 3px;
  text-align: center;
  font-size: 1.25rem;
}
.DetailView .DetailView__card .card-body-table .table-row > div.double {
  flex: 2;
  padding-left: 2px;
}
.DetailView .DetailView__card .card-body-table .table-row > div.double label {
  margin-left: -2px;
}
.DetailView .DetailView__card .card-body-table .table-row > div:not(:last-child) {
  border-right: 1px solid #727e96;
}
.DetailView .DetailView__card .card-body-table .table-row > div:hover,
.DetailView .DetailView__card .card-body-table .table-row > div label:hover {
  cursor: help;
}
@media (max-width: 899px) {
  .DetailView .DetailView__card .card-body-table .table-row > div {
    font-size: 1rem;
  }
}
.DetailView .DetailView__card .card-body-table .table-row > div > label {
  display: block;
  line-height: 1.1;
  font-size: 0.875rem;
  padding: 1px 0;
  border-bottom: 1px solid #727e96;
  margin-bottom: 3px;
  color: #000;
  background-image: linear-gradient(to right top, #f8f9fa 33%, #e0e5e9 33%, #e0e5e9 66%, #f8f9fa 66%);
  background-size: 3px 3px;
}
@media (max-width: 599px) {
  .DetailView .DetailView__card .card-body-table .table-row > div > label {
    font-size: 1rem;
  }
}
.DetailView .DetailView__card .card-body-table .table-row > div span {
  margin: 0 3px;
}
.DetailView .DetailView__card .card-body-table .table-row .table-content {
  margin: 0.63rem 0.47rem;
  line-height: 1rem;
}
.DetailView .DetailView__card .card-body-table .table-row .table-small-font {
  font-size: 0.875rem;
}
@media (max-width: 599px) {
  .DetailView .DetailView__card .card-body-table .table-row .table-small-font {
    font-size: 1rem;
  }
}
.DetailView .DetailView__card .card-body-landlord,
.DetailView .DetailView__card .card-body-complaints,
.DetailView .DetailView__card .card-body-links {
  margin-top: 1.5rem;
}
.DetailView .DetailView__card .card-body-complaints b,
.DetailView .DetailView__card .card-body-links b {
  display: block;
  border-bottom: 1px solid #727e96;
}
.DetailView .DetailView__card .card-body-complaints li,
.DetailView .DetailView__card .card-body-links li {
  line-height: 1.13rem;
}
.DetailView .DetailView__card .card-body-landlord .landlord-contact-info:not(:last-child) {
  margin-bottom: 1.13rem;
}
.DetailView .DetailView__card .card-body-landlord .card-title-landlord {
  border-bottom: 1px solid #727e96;
}
@media (min-width: 600px) {
  .DetailView .DetailView__card .card-body-landlord .card-title-landlord b {
    display: block;
    max-width: 75%;
  }
}
.DetailView .DetailView__card .card-body-landlord .card-title-landlord details.accordion {
  padding: 0;
}
@media (max-width: 599px) {
  .DetailView .DetailView__card .card-body-landlord .card-title-landlord details.accordion {
    display: none;
  }
}
.DetailView .DetailView__card .card-body-landlord .card-title-landlord details.accordion div {
  margin: 0;
  padding: 0 0.5rem;
}
.DetailView .DetailView__card .card-body-landlord .card-title-landlord details.accordion summary {
  width: fit-content;
  float: right;
  margin-top: calc(-1 * 1.428571429 * 0.875rem);
  padding: 0;
  font-style: italic;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .DetailView .DetailView__card .card-body-landlord .card-title-landlord details.accordion summary {
    display: none;
  }
}
.DetailView .DetailView__card .card-body-landlord .card-title-landlord details.accordion .icon {
  display: none;
}
@media (min-width: 600px) {
  .DetailView .DetailView__card .card-body-landlord .card-footer-landlord {
    display: none;
  }
}
.DetailView .DetailView__card .card-body-landlord .card-footer-landlord details.accordion {
  padding: 0.31rem 0 0 0;
}
.DetailView .DetailView__card .card-body-landlord .card-footer-landlord details.accordion div {
  margin: 0;
}
.DetailView .DetailView__card .card-body-landlord .card-footer-landlord details.accordion .icon {
  display: none;
}
.DetailView .DetailView__card .card-body-landlord .card-footer-landlord details.accordion summary {
  text-decoration: underline;
  font-style: italic;
}
.DetailView .DetailView__card .card-body-registration {
  margin-top: 1.56rem;
}
.DetailView .DetailView__card .card-body-registration b {
  display: inline;
  border-bottom: none;
}
.DetailView .DetailView__card .card-body-prompt,
.DetailView .DetailView__card .card-body-social {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
.DetailView .DetailView__card h6.DetailView__subtitle {
  font-weight: bold;
  margin-bottom: 0.47rem;
}

.DetailView__compareTable {
  width: 100%;
  border-spacing: 0;
  margin-top: 25px;
}
.DetailView__compareTable th,
.DetailView__compareTable td {
  padding: 15px 30px;
}
.DetailView__compareTable th {
  font-size: 16px;
  width: 50%;
}
.DetailView__compareTable th em {
  font-weight: normal;
  font-size: 0.63rem;
}
.DetailView__compareTable th:first-child {
  border-top: 1px dashed #acb3c2;
  border-left: 1px dashed #acb3c2;
  border-right: 1px dashed #acb3c2;
}
.DetailView__compareTable td {
  text-align: left;
  vertical-align: top;
}
.DetailView__compareTable td:first-child {
  border-left: 1px dashed #acb3c2;
  border-right: 1px dashed #acb3c2;
}
.DetailView__compareTable td div {
  border-bottom: 1px solid #727e96;
}
.DetailView__compareTable td ul {
  margin: 0.31rem;
}
.DetailView__compareTable td ul li {
  margin-top: 0.31rem;
}
.DetailView__compareTable tr:last-child td:first-child {
  border-bottom: 1px dashed #acb3c2;
}
