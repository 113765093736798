@import "_vars.scss";

@mixin input() {
  display: inline-block;
  border: 1px solid $justfix-black;
  padding: 0.63rem;
  font-size: inherit;
  border-radius: 4px;
  font-weight: 400;
  outline: none;
  width: unset;
}

.input {
  @include input();
}
