@import "~@justfixnyc/component-library/dist/src/assets/index.css"; /*
  Copied/adapted from https://github.com/JustFixNYC/justfix-website/blob/master/src/styles/_design-system.scss
  - wow uses root 10px, org-site uses 16px. All rem units here have been adjusted
  - font files have been relocated to '/public/fonts' and are preloaded/initialized in index.html
 */
.jfcl-button,
.jfcl-link {
  font-family: "Inconsolata", monospace;
}

.download-btn-link,
.download-btn-link:hover,
.download-btn-link:active,
.download-btn-link:focus {
  text-decoration: none;
}

.App {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.App > div {
  box-sizing: border-box;
}
.App a:not(.btn):not(.image):focus,
.App summary:focus-visible,
.App button.dropdown-toggle:focus {
  outline-style: solid;
  outline-width: 2px;
  outline-color: #5188ff;
}

.App__warning {
  padding: 30px 15px 15px 15px;
  background-color: #454d5d;
  color: #fff;
  text-align: center;
}
.App__warning a {
  color: #fff;
}

.App__banner {
  padding: 0.31rem 0.94rem;
  color: #242323;
  position: relative;
  background-color: #ffba33;
}
.App__banner .close-button {
  color: #242323;
  position: absolute;
  right: 0.31rem;
  top: 0rem;
  padding: 0 0.63rem;
  font-size: 1.25rem;
  height: 100%;
  cursor: pointer;
}
.App__banner .close-button:hover {
  opacity: 0.7;
}
.App__banner button:focus {
  outline: 2px solid #454d5d;
  outline-offset: 2px;
}
.App__banner .content {
  text-align: center;
}
@media (max-width: 599px) {
  .App__banner .content {
    text-align: left;
    max-width: 95%;
  }
}
.App__banner .content p {
  margin-bottom: 0;
}
.App__banner .content a,
.App__banner .content button:not(.close-button) {
  color: #242323;
  text-decoration: underline;
  font-weight: 700;
}
.App__banner .content a:hover, .App__banner .content a:focus,
.App__banner .content button:not(.close-button):hover,
.App__banner .content button:not(.close-button):focus {
  text-decoration: none;
}

.App__header {
  background-color: #f8f9fa;
  border-bottom: 1px solid black;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 0 1.5rem 0 2rem;
}
@media (max-width: 599px) {
  .App__header {
    padding: 0 1.5rem 0 1.5rem;
  }
}
.App__header.wowza-styling {
  align-items: center !important;
  background-color: #242323;
}
.App__header.wowza-styling > a h4,
.App__header.wowza-styling button.btn.btn-link.dropdown-toggle {
  color: #faf8f4;
}
@media (min-width: 900px) {
  .App__header.wowza-styling {
    color: #faf8f4;
  }
  .App__header.wowza-styling nav a,
  .App__header.wowza-styling nav button {
    color: #faf8f4;
  }
}
.App__header.wowza-styling .menu {
  background-color: #faf8f4;
  color: #242323;
}
.App__header .label.label-warning {
  color: #454d5d;
  transform: rotate(-4deg);
  height: min-content;
  margin-left: 12px;
}
@media (max-width: 599px) {
  .App__header .label.label-warning {
    font-size: 0.63rem;
    width: min-content;
  }
}
.App__header h1.page-title {
  font-family: "Degular", Arial, Helvetica, sans-serif;
  font-size: 0.7rem;
  line-height: 100%;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.01rem;
  font-weight: 600;
  font-size: 1.13rem;
  letter-spacing: 0.02em;
  font-variant: all-small-caps;
  margin: 0 0 0 1.5rem;
  color: #faf8f4;
}
@media (max-width: 599px) {
  .App__header h1.page-title {
    margin: 1.5rem 0;
  }
}
.App__header > a,
.App__header a:hover,
.App__header a:focus {
  text-decoration: none !important;
}
.App__header > a {
  width: fit-content;
  max-width: 75%;
  text-align: center;
  display: flex;
  align-items: center;
}
.App__header > a .jf-logo {
  color: #faf8f4;
  margin-right: 1.5rem;
}
@media (max-width: 599px) {
  .App__header > a .jf-logo {
    display: none;
  }
}
.App__header > a .jf-logo-divider {
  color: #faf8f4;
}
@media (max-width: 599px) {
  .App__header > a .jf-logo-divider {
    display: none;
  }
}
.App__header > a .legacy-styling {
  color: black;
}
.App__header nav {
  display: inline-flex;
  flex-wrap: nowrap;
  margin-left: auto;
  text-align: end;
  align-items: center;
}
@media (max-width: 899px) {
  .App__header nav {
    align-items: center;
  }
}
.App__header nav a,
.App__header nav button {
  display: inline-block;
  margin-left: 10px;
  color: #454d5d;
  text-decoration: underline;
}
.App__header nav a.active, .App__header nav a:hover,
.App__header nav button.active,
.App__header nav button:hover {
  text-decoration: none;
}
.App__header nav .menu .menu-item > button {
  text-decoration: none;
  color: inherit;
  display: inline;
  margin: 0 -0.5rem;
  padding: 0.37rem 0.5rem;
}
.App__header nav .language-toggle a {
  margin-right: 0;
}
.App__header nav .language-toggle a:last-child {
  margin: 0;
}

.App__body {
  flex: 1;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  -webkit-overflow-scrolling: touch;
  overflow: auto;
  display: flex;
}
.App__body.wowza-styling, .App__body.wowza-styling .Loader-map, .App__body.wowza-styling .EngagementPanel {
  color: #242323;
  background-color: #faf8f4;
  border-color: #242323;
}

div.Page {
  min-height: 100%;
  min-width: 100%;
  display: flex;
  flex-direction: column;
}
div.Page h4 {
  text-decoration: underline;
}
div.Page p {
  margin-bottom: 0.78rem;
}
div.Page .page-container {
  width: min(100vw - 2.5rem, 37.5rem);
  max-width: 37.5rem;
  margin: 2.5rem auto;
}
div.Page .page-container h4 {
  text-decoration: none;
  text-align: center;
}
div.Page .page-container form {
  display: flex;
  flex-direction: column;
}
div.Page .page-container form .form-label {
  font-family: "Degular", Arial, Helvetica, sans-serif;
  font-size: 0.7rem;
  line-height: 100%;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.01rem;
  font-family: "Suisse Int'l Mono", "Courier New", Courier, monospace;
  line-height: 115%;
  letter-spacing: 0.03em;
  text-transform: uppercase;
}
div.Page .page-container form input[type=submit] {
  margin: 0.63rem auto;
}

.Page__content {
  padding: 30px;
  width: 100%;
  flex-grow: 1;
}
.Page__content li p {
  display: inline;
}
@media (max-width: 899px) {
  .Page__content h5 {
    margin-top: 2.5rem;
  }
  .Page__content aside {
    margin-top: 48px;
    margin-bottom: 48px;
  }
  .Page__content aside.contentful-asset {
    display: none;
  }
}
@media (min-width: 900px) {
  .Page__content aside {
    width: 38%;
    float: right;
  }
  .Page__content aside > div, .Page__content aside > figure {
    max-width: 360px;
    margin: 0 auto;
  }
  .Page__content aside img {
    margin: 0 auto;
  }
  .Page__content aside figcaption {
    max-width: 300px;
    margin: 0.63rem auto;
    font-size: 0.78rem;
    line-height: 1.1;
  }
  .Page__content p,
  .Page__content h1,
  .Page__content h2,
  .Page__content h3,
  .Page__content h4,
  .Page__content h5,
  .Page__content h6 {
    width: 55vw;
    display: inline-block;
  }
  .Page__content ul,
  .Page__content ol {
    margin-top: 0;
    max-width: 50vw;
  }
}
