.EngagementPanel {
  width: max-content;
  max-width: 100%;
  background-color: #f8f9fa;
  border: 1px solid #727e96;
  margin: 104px auto 104px auto;
  padding: 30px;
}
@media (max-width: 599px) {
  .EngagementPanel {
    margin: 72px auto 72px auto;
    box-sizing: border-box;
    padding: 30px 15px;
  }
}
.EngagementPanel h5 {
  text-align: center;
  font-style: italic;
}
.EngagementPanel .EngagementWrapper {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  flex-direction: row;
}
@media (max-width: 1199px) {
  .EngagementPanel .EngagementWrapper {
    display: block;
    align-items: center;
    flex-direction: column;
  }
  .EngagementPanel .EngagementWrapper .Subscribe form {
    display: flex;
    flex-direction: column;
  }
  .EngagementPanel .EngagementWrapper .Subscribe form input {
    margin-bottom: 8px;
  }
}
.EngagementPanel .EngagementWrapper .EngagementItem {
  margin-left: 16px;
  margin-right: 16px;
}
@media (max-width: 1199px) {
  .EngagementPanel .EngagementWrapper .EngagementItem {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .EngagementPanel .EngagementWrapper .EngagementItem:nth-of-type(2) {
    margin-top: 24px;
    margin-bottom: 24px;
  }
}
.EngagementPanel .EngagementWrapper .EngagementItem p {
  margin-bottom: 3px;
  color: #454d5d;
}
.EngagementPanel .EngagementWrapper .EngagementItem .btn-block {
  min-width: 175px;
}
