.PropertiesMap {
  display: inline-block;
  width: 30vw;
  position: relative;
  z-index: 5;
}
@media (min-width: 900px) {
  .PropertiesMap {
    flex-grow: 1;
  }
}
@media (max-width: 599px) {
  .PropertiesMap {
    width: 100%;
  }
}
@media (min-width: 600px) {
  .PropertiesMap .mapboxgl-ctrl-bottom-right {
    bottom: 112px;
  }
}
@media (max-width: 599px) {
  .PropertiesMap .mapboxgl-ctrl-bottom-right,
  .PropertiesMap .mapboxgl-ctrl-bottom-left {
    bottom: 98px;
    transform: translateY(3.25rem);
    transition: transform 250ms ease-in-out;
  }
  .PropertiesMap:has(.PropertiesMap__legend--slide) .mapboxgl-ctrl-bottom-right,
  .PropertiesMap:has(.PropertiesMap__legend--slide) .mapboxgl-ctrl-bottom-left {
    transform: translateY(0px);
  }
}
.PropertiesMap .mapboxgl-map {
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.PropertiesMap .mapboxgl-map canvas {
  display: block;
}
.PropertiesMap .mapboxgl-map .MapAlert__container {
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  margin: 0.63rem 1.38rem 0 2.81rem;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}
.PropertiesMap .PropertiesMap__error {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  position: relative;
  z-index: 30;
}
.PropertiesMap .PropertiesMap__error h4 {
  padding: 1.88rem;
}
.PropertiesMap .PropertiesMap__legend {
  background: #ffffff;
  position: absolute;
  z-index: 10;
  right: 0;
  bottom: 0;
  border-left: 1px solid #000;
  border-top: 1px solid #000;
  padding: 1rem;
}
.PropertiesMap .PropertiesMap__legend p {
  font-size: 0.875rem;
  line-height: 100%;
  text-align: center;
  padding: 0 0 1.25rem 0;
  margin: 0;
}
.PropertiesMap .PropertiesMap__legend p span {
  text-transform: uppercase;
}
.PropertiesMap .PropertiesMap__legend p i {
  display: none;
  font-style: normal;
  font-size: 14px;
}
@media (max-width: 599px) {
  .PropertiesMap .PropertiesMap__legend p i {
    display: inline;
  }
}
.PropertiesMap .PropertiesMap__legend .legend-entry-container {
  display: flex;
  flex-direction: column;
  gap: 0.63rem;
  padding: 0;
}
.PropertiesMap .PropertiesMap__legend .legend-entry-container div {
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  line-height: 100%;
}
.PropertiesMap .PropertiesMap__legend .legend-entry-container div:before {
  content: "";
  display: inline-block;
  flex: none;
  height: 16px;
  width: 16px;
  border-radius: 16px;
  position: relative;
  margin-right: 0.31rem;
  background-color: black;
  border: 1px solid #111;
}
.PropertiesMap .PropertiesMap__legend .legend-entry-container div.addr-search:before {
  background-color: #ff5722;
}
.PropertiesMap .PropertiesMap__legend .legend-entry-container div.addr-assoc:before {
  background-color: #ff9800;
}
.PropertiesMap .PropertiesMap__legend .legend-entry-container div.addr-filter:before {
  background-color: #faf8f4;
  border: 2px solid #5188ff;
}
@media (max-width: 599px) {
  .PropertiesMap .PropertiesMap__legend {
    width: 100%;
    border-right: 1px solid #000;
    gap: 0.94rem;
    padding: 0;
    transform: translateY(calc(100% - 3rem));
    transition: transform 250ms ease-in-out;
  }
  .PropertiesMap .PropertiesMap__legend.PropertiesMap__legend--slide {
    transform: translateY(1px);
  }
  .PropertiesMap .PropertiesMap__legend p {
    padding: 1.06rem;
  }
  .PropertiesMap .PropertiesMap__legend p span {
    font-weight: 700;
    text-decoration: underline;
  }
  .PropertiesMap .PropertiesMap__legend .legend-entry-container {
    padding: 0 0.63rem 0.63rem 0.63rem;
  }
}

.PropertiesList .PropertiesMap {
  height: 100%;
  width: 100%;
}
.PropertiesList .PropertiesMap.is-hidden {
  display: none !important;
}
@media (min-width: 600px) {
  .PropertiesList .PropertiesMap .MapAlert__container {
    width: fit-content;
  }
}
.PropertiesList .PropertiesMap .MapAlert__container .selected-addr-alert {
  width: 22.75rem;
  text-transform: uppercase;
}
@media (max-width: 599px) {
  .PropertiesList .PropertiesMap .MapAlert__container .selected-addr-alert {
    width: 100%;
  }
}
.PropertiesList .PropertiesMap .MapAlert__container .selected-addr-alert .jf-alert__content p,
.PropertiesList .PropertiesMap .MapAlert__container .selected-addr-alert .jf-alert__content a {
  color: #242323;
}
.PropertiesList .PropertiesMap .MapAlert__container .selected-addr-alert .selected-addr-alert__address {
  font-size: 1rem;
}
.PropertiesList .PropertiesMap .MapAlert__container .selected-addr-alert a {
  text-underline-position: under;
}
@media (min-width: 600px) {
  .PropertiesList .PropertiesMap .mapboxgl-ctrl-bottom-right,
  .PropertiesList .PropertiesMap .mapboxgl-ctrl-bottom-left {
    bottom: 0;
  }
  .PropertiesList .PropertiesMap .PropertiesMap__legend {
    margin: 0 0.63rem 1.81rem 0;
  }
}
