.subscriptions-container .subscription-field,
.subscriptions-container .unsubscribe-all-field {
  display: flex;
  border-top: 1px solid #c5ccd1;
  padding: 1rem 0;
}
.subscriptions-container .unsubscribe-all-field {
  justify-content: end;
}
.subscriptions-container .unsubscribe-all-field button {
  font-size: 1rem;
  text-decoration: none;
}
.subscriptions-container a.subscription-address {
  display: flex;
  flex: 1;
  flex-direction: column;
  color: #242323;
  cursor: pointer;
  font-weight: 600;
}
.subscriptions-container a.subscription-address:hover {
  text-decoration: none;
}
.subscriptions-container .street-address {
  text-transform: capitalize;
  font-size: 1.125rem;
}

.settings-no-subscriptions {
  padding: 1.6rem;
  background-color: #f2f2f2;
  border-radius: 4px;
}

.settings-contact {
  margin-bottom: 1.5rem;
  text-align: center;
}
