/*
  Copied/adapted from https://github.com/JustFixNYC/justfix-website/blob/master/src/styles/_design-system.scss
  - wow uses root 10px, org-site uses 16px. All rem units here have been adjusted
  - font files have been relocated to '/public/fonts' and are preloaded/initialized in index.html
 */
.send-new-link-container {
  display: flex;
}
.send-new-link-container .send-new-link {
  display: flex;
}
.send-new-link-container .link-sent {
  display: flex;
  align-items: center;
}
.send-new-link-container .link-sent svg {
  height: 20px;
  width: 20px;
  margin-right: 0.5rem;
}
