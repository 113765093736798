@import "_vars";
@import "_typography";
@import "_button.scss";

.minmaxselect-container {
  display: flex;
  flex-direction: column;
  padding: 0.75rem;
  gap: 0.75rem;
  font-weight: 600;
  @include browser("Firefox") {
    font-weight: 500;
  }
  .minmaxselect__presets-container {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 2.5rem 2.5rem;
    gap: 1px;
    .minmaxselect__preset-value {
      position: relative;
      display: flex;
      box-shadow: 0 0 0 1px $justfix-grey-700;
      color: $justfix-black;
      background-color: $justfix-white;
      &:has(> input[disabled]) {
        opacity: 0.5;
        background-color: lightgrey;
      }
      &:has(> input.checked) {
        background-color: $justfix-black;
        color: $justfix-white;
      }
      &:focus-within {
        outline: 0.13rem solid $justfix-black;
        z-index: 1;
      }
      input[type="checkbox"] {
        // hide in a11y-friendly way
        position: absolute;
        opacity: 0;
        width: 1em;
        height: 1em;
        top: 0.31rem;
        left: 0.94rem;
      }
      label {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }
  }

  .minmaxselect__controls-container {
    display: flex;
    justify-content: space-between;
    .minmaxselect__clear-value-button,
    .minmaxselect__custom-range-summary span {
      text-transform: none;
      font-size: 0.75rem;
      text-decoration: underline;
      text-underline-position: under;
      color: $justfix-grey-700;
    }
    .minmaxselect__custom-range-summary {
      display: flex;
      align-items: center;
      width: fit-content;
      cursor: pointer;
      &::-webkit-details-marker {
        display: none;
      }
      svg {
        height: 0.69rem;
        margin-left: 0.13rem;
      }
    }
    details[open] > summary svg {
      transform: scaleY(-1);
    }
    .minmaxselect__clear-value-button {
      height: fit-content;
    }
    .minmaxselect__custom-range-container {
      display: flex;
      flex-direction: column;
      padding-top: 0.75rem;
      gap: 0.75rem;
      .minmaxselect__label-input-container {
        display: flex;
        flex-direction: column;
        width: fit-content;
        gap: 0.25rem;
        color: $justfix-grey-700;
        font-size: 0.75rem;
        .minmaxselect__labels-container {
          display: flex;
          label:first-child {
            margin-right: auto;
          }
        }
        .minmaxselect__inputs-container {
          display: flex;
          gap: 0.63rem;
          align-items: center;
          input {
            --input-size: 2.75rem;
            --input-padding: 0.5rem;
            width: calc(var(--input-size) - (var(--input-padding) * 2));
            height: calc(var(--input-size) - (var(--input-padding) * 2));
            padding: var(--input-padding);
            background-color: $justfix-white;
            color: $justfix-black;
            font-size: 1rem;
            border: 1px solid $justfix-grey-600;
            border-radius: 0.13rem;
            appearance: textfield;
            // remove increment arrows
            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }
            &:focus-visible {
              outline: none;
            }
            &:focus {
              border: 2px $justfix-black solid;
              box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
            }
            &.hasError {
              outline: 3px solid $justfix-orange;
            }
          }
        }
      }
    }
  }
  button.button.is-primary {
    align-self: center;
    width: 100%;
    letter-spacing: 0.02em;
  }
}
