/*
  Copied/adapted from https://github.com/JustFixNYC/justfix-website/blob/master/src/styles/_design-system.scss
  - wow uses root 10px, org-site uses 16px. All rem units here have been adjusted
  - font files have been relocated to '/public/fonts' and are preloaded/initialized in index.html
 */
/*
  Copied/adapted from https://github.com/JustFixNYC/justfix-website/blob/master/src/styles/_design-system.scss
  - wow uses root 10px, org-site uses 16px. All rem units here have been adjusted
  - font files have been relocated to '/public/fonts' and are preloaded/initialized in index.html
 */
.jf-callout {
  font-family: "Inconsolata", monospace;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
  letter-spacing: 0.042rem;
  background-color: #efe9dc;
  padding: 0.75rem;
}

.AccountSettingsPage .page-container {
  padding-top: 1.5rem;
}
.AccountSettingsPage h1 {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 100%;
  margin-bottom: 3rem;
}
.AccountSettingsPage .settings-section {
  margin-bottom: 3rem;
}
.AccountSettingsPage .settings-section:first-of-type {
  border-top: 1px solid #c5ccd1;
  border-bottom: 1px solid #c5ccd1;
  padding-bottom: 3rem;
}
.AccountSettingsPage .settings-section .log-in-out {
  display: flex;
  justify-content: space-between;
  padding: 1rem 0 3rem 0;
  align-items: baseline;
}
.AccountSettingsPage .settings-section h2 {
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: 0;
  margin-bottom: 1rem !important;
}
.AccountSettingsPage .settings-section .UserSetting:nth-of-type(1) {
  margin-bottom: 1rem;
}
.AccountSettingsPage .settings-section .section-description {
  margin-bottom: 2rem;
}
.AccountSettingsPage label {
  font-size: 0.81rem;
  font-weight: 600;
}
.AccountSettingsPage p {
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 100%;
}

.UserSetting form {
  display: flex;
  flex-direction: column;
}
.UserSetting form .page-level-alert {
  margin-bottom: 1.625rem;
}
.UserSetting form .page-level-alert svg {
  margin-right: 0.5rem;
  vertical-align: bottom;
}
.UserSetting form .user-setting-label {
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: 0.26px;
  text-transform: uppercase !important;
  margin-bottom: 1rem !important;
}
.UserSetting form p {
  margin-bottom: 0.375rem;
}
.UserSetting form .email-input-field,
.UserSetting form .password-input-field {
  display: flex;
  flex-direction: column;
}
.UserSetting form .email-input-field:has(#new-password-input),
.UserSetting form .password-input-field:has(#new-password-input) {
  margin-top: 1.25rem;
}
.UserSetting form .jf-callout {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.625;
}
.UserSetting form > div {
  display: flex;
}
.UserSetting form > div span {
  flex: 1;
}
.UserSetting form > div span,
.UserSetting form > div .edit-button {
  font-size: 1rem;
}
.UserSetting .user-setting-actions {
  display: flex;
  justify-content: left;
  align-items: center;
  margin-top: 1rem;
  column-gap: 1rem;
}
@media (max-width: 599px) {
  .UserSetting .user-setting-actions {
    justify-content: center;
  }
}
