@import "_vars.scss";

// $scroll-border: $dark;
// $scroll-body: $gray-dark;

// $scroll-border: $gray-dark;
// $scroll-body: $gray;

// $scroll-border: $gray;
// $scroll-body: $gray-light;

// $scroll-border: $gray;
// $scroll-body: darken($gray-light, 5%);

$scroll-border: $gray-dark;
$scroll-body: darken($background, 15%);

@mixin scrollbar() {
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    width: 18px;
    background-color: #fff;
  }

  &::-webkit-scrollbar-track {
    background-color: #fff;

    &:vertical {
      border-left: 1px solid $gray-dark;
      border-right: 1px solid $gray-dark;
    }
    &:horizontal {
      border-top: 1px solid $gray-dark;
    }
  }

  &::-webkit-scrollbar-thumb {
    background-color: $scroll-body;

    position: relative;
    margin-top: -1px;

    border: 1px solid $gray-dark;

    &:vertical {
      border-top: none;
    }

    box-shadow: inset -1px -1px 0px 0px $scroll-border,
      inset 2px 2px 0px 0px lighten($scroll-body, 10%);
  }
}

@mixin scrollbar_wowza($track-radius: inherit, $thumb-radius: inherit, $bg-color: $justfix-white) {
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    border-radius: $track-radius;
    background-color: $bg-color;
    width: 1.13rem;
  }
  &::-webkit-scrollbar-track {
    border-radius: $track-radius;
    background-color: $bg-color;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: $thumb-radius;
    background-color: $justfix-grey;
    border: 3px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
  }
}
