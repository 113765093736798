.StandalonePage div.page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  margin-top: 0;
  margin-bottom: 0;
  padding: 4rem 0 1.5rem 0;
}
@media (max-width: 599px) {
  .StandalonePage div.page-container {
    margin: 0;
    width: 100%;
    padding: 1.5rem 1rem;
  }
}
.StandalonePage div.page-container .jf-logo {
  display: flex;
}
@media (max-width: 599px) {
  .StandalonePage div.page-container .jf-logo {
    margin-top: 1rem;
  }
}
.StandalonePage div.page-container .jf-logo svg {
  color: #242323;
  height: 1rem;
}
.StandalonePage div.page-container .standalone-container {
  display: flex;
  flex-direction: column;
  background-color: #efe9dc;
  margin: 2rem 0;
  width: 464px;
  padding: 3rem 4rem;
}
@media (max-width: 599px) {
  .StandalonePage div.page-container .standalone-container {
    width: 100%;
    padding: 1.5rem;
  }
}
.StandalonePage div.page-container .standalone-container .Loader {
  background-color: inherit;
}
.StandalonePage div.page-container .standalone-container .jf-alert {
  margin-bottom: 1.5rem;
}
.StandalonePage div.page-container .standalone-container h1,
.StandalonePage div.page-container .standalone-container h2,
.StandalonePage div.page-container .standalone-container p {
  margin: 0;
  text-align: left;
}
.StandalonePage div.page-container .standalone-container h1,
.StandalonePage div.page-container .standalone-container h1 a,
.StandalonePage div.page-container .standalone-container h2,
.StandalonePage div.page-container .standalone-container h2 a,
.StandalonePage div.page-container .standalone-container p,
.StandalonePage div.page-container .standalone-container p a {
  font-weight: 500;
}
.StandalonePage div.page-container .standalone-container h1 {
  font-size: 1.5rem;
}
.StandalonePage div.page-container .standalone-container h2,
.StandalonePage div.page-container .standalone-container h2 a,
.StandalonePage div.page-container .standalone-container p,
.StandalonePage div.page-container .standalone-container p a {
  font-size: 0.875rem;
}
.StandalonePage div.page-container .standalone-container h1 + h2 {
  margin-top: 1rem;
}
.StandalonePage div.page-container .standalone-container .input-group,
.StandalonePage div.page-container .standalone-container .email-input-field + .password-input-field,
.StandalonePage div.page-container .standalone-container .jfcl-button.jfcl-variant-primary {
  margin-top: 1.5rem;
}
.StandalonePage div.page-container .standalone-container .send-new-link,
.StandalonePage div.page-container .standalone-container .jfcl-button {
  width: 100%;
}
.StandalonePage div.page-container .standalone-container a.jfcl-button {
  text-decoration: none;
}
.StandalonePage div.page-container .standalone-container .didnt-get-link {
  margin-top: 1rem;
}
.StandalonePage div.page-container .standalone-container .send-new-link-container {
  margin-top: 1.5rem;
}
.StandalonePage div.page-container .standalone-container .standalone-footer,
.StandalonePage div.page-container .standalone-container .login-footer,
.StandalonePage div.page-container .standalone-container .unsubscribe-footer,
.StandalonePage div.page-container .standalone-container .address-page-link {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  text-align: center;
  margin-top: 1.5rem;
}
.StandalonePage div.page-container .standalone-container .standalone-footer,
.StandalonePage div.page-container .standalone-container .standalone-footer a,
.StandalonePage div.page-container .standalone-container .standalone-footer .button.is-text,
.StandalonePage div.page-container .standalone-container .login-footer,
.StandalonePage div.page-container .standalone-container .login-footer a,
.StandalonePage div.page-container .standalone-container .login-footer .button.is-text,
.StandalonePage div.page-container .standalone-container .unsubscribe-footer,
.StandalonePage div.page-container .standalone-container .unsubscribe-footer a,
.StandalonePage div.page-container .standalone-container .unsubscribe-footer .button.is-text,
.StandalonePage div.page-container .standalone-container .address-page-link,
.StandalonePage div.page-container .standalone-container .address-page-link a,
.StandalonePage div.page-container .standalone-container .address-page-link .button.is-text {
  font-size: 0.875rem;
  font-weight: 500;
  color: #464d5d;
}
.StandalonePage div.page-container .standalone-container .standalone-footer .button.is-text,
.StandalonePage div.page-container .standalone-container .login-footer .button.is-text,
.StandalonePage div.page-container .standalone-container .unsubscribe-footer .button.is-text,
.StandalonePage div.page-container .standalone-container .address-page-link .button.is-text {
  display: inline;
}
.StandalonePage div.page-container .standalone-container .address-page-link {
  text-align: left;
}
.StandalonePage div.page-container .standalone-container .address-page-link a {
  color: #242323;
}
.StandalonePage div.page-container .standalone-container .address-page-link a svg {
  rotate: 180deg;
  margin-right: 10px;
  height: 1em;
  vertical-align: -0.125em;
}
.StandalonePage div.page-container .wow-footer {
  margin-top: auto;
  text-align: center;
}
.StandalonePage div.page-container .wow-footer,
.StandalonePage div.page-container .wow-footer a {
  font-size: 0.875rem;
}
