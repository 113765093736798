@media (max-width: 599px) {
  .AddressToolbar {
    display: none;
  }
}
.AddressToolbar .btn-group {
  flex-wrap: nowrap;
  min-width: 11.88rem;
  padding-left: 0.5rem;
  align-items: center;
}
.AddressToolbar ul.menu {
  border: 1px solid #727e96;
  padding: 0;
  width: 15rem;
  right: 0;
  left: inherit;
  list-style: disc inside none;
}
.AddressToolbar ul.menu li {
  margin-top: 0;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
}
.AddressToolbar ul.menu li a {
  text-decoration: none;
}
.AddressToolbar ul.menu li:hover {
  background-color: #454d5d;
  color: #fff;
  cursor: pointer;
}
.AddressToolbar ul.menu li:hover a {
  color: #fff;
}
