.AddressSearch form {
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

@media screen and (max-width: 480px) {
  .AddressSearch form {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.AddressSearch form .form-group {
  margin-right: 10px;
  margin-bottom: 0;
}

.AddressSearch form .form-group .btn {
  margin-top: 32px;
}

.AddressSearch .geosuggest {
  margin-bottom: 15px;
}
.AddressSearch .geosuggest .geosuggest__suggests-wrapper {
  background-color: #fff;
}
.AddressSearch .geosuggest .geosuggest__suggests-wrapper .geosuggest__suggests:not(.geosuggest__suggests--hidden) {
  border: 1px solid #acb3c2;
  border-top: none;
}
.AddressSearch .geosuggest .geosuggest__suggests-wrapper ul {
  margin: 0;
}
.AddressSearch .geosuggest .geosuggest__suggests-wrapper ul .geosuggest__item {
  margin-top: 0;
  padding: 0.47rem 0.63rem;
  list-style-type: none;
}
.AddressSearch .geosuggest .geosuggest__suggests-wrapper ul .geosuggest__item:hover {
  background-color: #acb3c2;
  cursor: pointer;
}
.AddressSearch .geosuggest .geosuggest__suggests-wrapper ul .geosuggest__item.geosuggest__item--active {
  background-color: #454d5d;
  color: #fff;
}
.AddressSearch .geosuggest .geosuggest__suggests-wrapper ul .geosuggest__item.geosuggest__item--active:hover {
  background-color: #454d5d;
}
