.PropertiesList {
  display: flex;
  flex-direction: column;
  height: 100%;
  font-family: "Inconsolata";
  font-style: normal;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 100%;
  letter-spacing: 0.02em;
}
